import { useEffect, useMemo, useState } from 'react'
import styles from './Profile.module.scss'
import EditIcon from '../../icons/edit.icon'
import { Form, Formik } from 'formik'
import CustomButton from '../shared/CustomButton/customButton'
import CloseIcon from '../../icons/close.icon'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import LoadingService from '../../services/loading/loading.service'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { CandidateService } from '../../services/candidate/candidate.service'
import SendIcon from '../../icons/send.icon'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'
import OverviewSearchIcon from '../../icons/overviewSearch.icon'

type Props = {
  pageIsLoading: boolean
  updateFlag: boolean
}

const OverviewProfile: React.FC<Props> = ({ pageIsLoading, updateFlag }) => {
  const [editable, setEditable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [overview, setOverview] = useState('')
  const [lastUpdatedAt, setLastUpdatedAt] = useState('')
  const [lastUpdatedBy, setLastUpdatedBy] = useState('')
  const [canRegenerate, setCanRegenerate] = useState(false)
  const [localUpdateFlag, setLocalUpdateFlag] = useState(false)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService()
  }, [])

  const validationSchema = Yup.object().shape({
    overview: Yup.string().required('Your overview cannot be blank.'),
  })

  useEffect(() => {
    loadingService
      .await(candidateService.getSummary())
      .then((res) => {
        if (res !== null && res.status === 'success') {
          setOverview(res.result.overview ?? 'No overview generated.')
          setLastUpdatedAt(res.result.overview_last_updated_at)
          setLastUpdatedBy(res.result.overview_last_updated_by)
          setCanRegenerate(res.result.can_regenerate_overview)
        }
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [candidateService, loadingService, updateFlag, localUpdateFlag])

  function handleSubmit(values: { overview: string }) {
    loadingService
      .await(candidateService.updateSummary({ overview: values.overview }))
      .then((res) => {
        if (res !== null && res.status === 'success') {
          setLocalUpdateFlag(!localUpdateFlag)
          setEditable(false)
        } else {
          setErrorMessage('Something went wrong. Please try again later.')
        }
      })
  }

  async function regenerateOverview() {
    loadingService
      .await(candidateService.generateOverview())
      .then((res) => {
        if (res !== null && res.status === 'success') {
          setLocalUpdateFlag(!localUpdateFlag)
        } else {
          setErrorMessage('Something went wrong. Please try again later.')
        }
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }

  return (
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
      <ErrorBannerModal
        open={errorMessage !== ''}
        onClose={() => setErrorMessage('')}
        errorMessage={errorMessage}
      />
      <Formik
        initialValues={{ overview: overview }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ values, errors, resetForm, setFieldValue }) => (
          <Form>
            <div
              className={`d-flex align-items-center justify-content-between ${styles.borderBottom}`}
            >
              <div className={'d-flex align-items-center'}>
                <OverviewSearchIcon />
                <h4 className={'text-almostBlack ms-3'}>Overview</h4>
              </div>
              <div className={'d-flex'}>
                {canRegenerate && (
                  <CustomButton
                    disabled={isLoading || pageIsLoading}
                    text={values.overview ? 'Regenerate' : 'Generate'}
                    className={`${styles.addBtn} ${styles.marginRight}`}
                    variant={'transparent'}
                    icon={<SendIcon />}
                    iconSide={'left'}
                    onClick={() => regenerateOverview()}
                  />
                )}
                <div>
                  {!editable && (
                    <div onClick={() => setEditable(true)}>
                      <EditIcon />
                    </div>
                  )}
                  {editable && (
                    <div
                      onClick={() => {
                        resetForm()
                        setEditable(false)
                      }}
                    >
                      <CloseIcon />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex">
                {values.overview ? (
                  <textarea
                    className={`${!editable ? styles.noBorder : ''} ${styles.width100}`}
                    disabled={!editable}
                    rows={8}
                    value={values.overview}
                    onChange={(e) => {
                      if (!isLoading && !pageIsLoading) {
                        setFieldValue('overview', e.target.value)
                      }
                    }}
                  />
                ) : (
                  <p className={'text-almostBlack'}>No overview generated.</p>
                )}
              </div>
              {lastUpdatedAt && (
                <p>
                  Last updated on{' '}
                  {new Date(lastUpdatedAt).toLocaleDateString('en-UK', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                  {lastUpdatedBy && ` by ${lastUpdatedBy}`}.
                </p>
              )}
              <p>Powered by GPT-4.</p>
            </div>
            {editable && (
              <div className={styles.bottomContent}>
                <CustomButton
                  text={'Save Changes'}
                  loading={isLoading}
                  type={'submit'}
                  disabled={
                    isLoading ||
                    pageIsLoading ||
                    !isEmpty(errors) ||
                    values.overview === overview
                  }
                  className={styles.saveBtn}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default OverviewProfile
