import React, { useState } from 'react'
import { useField } from 'formik'
import { X, Check } from 'lucide-react'
import styles from './PasswordField.module.scss'
import EyeIcon from '../../icons/eye.icon'
import HideEyeIcon from '../../icons/hideEye.icon'

interface Props {
  name: string
  placeholder?: string
}

export const PasswordField: React.FC<Props> = ({
  name,
  placeholder = 'Password *',
}) => {
  const [field] = useField(name)
  const [inputType, setInputType] = useState('password')

  const validateRequirements = (value: string) => {
    return {
      hasLength: value.length >= 8,
      hasUpperLower: /^(?=.*[a-z])(?=.*[A-Z])/.test(value),
      hasNumber: /\d/.test(value),
      hasSymbol: /[@$!%*#?&]/.test(value),
    }
  }

  const requirements = validateRequirements(field.value)

  const getIcon = (requirement: boolean) => {
    if (requirement) {
      return <Check className={styles.checkIcon} size={16} />
    }
    return <X className={styles.xIcon} size={16} />
  }

  const handleChangeFieldType = () => {
    setInputType(inputType === 'password' ? 'text' : 'password')
  }

  return (
    <div className="w-100">
      <div className={styles.formField}>
        <input
          {...field}
          id={name}
          className={styles.customField}
          type={inputType}
          placeholder={placeholder}
        />
        <label htmlFor={name} className={`form-label ${styles.formLabel}`}>
          {placeholder}
        </label>
        <button
          type="button"
          className={`btn ${styles.showHidePassword}`}
          onClick={handleChangeFieldType}
        >
          {inputType === 'password' ? <HideEyeIcon /> : <EyeIcon />}
        </button>
      </div>

      {field.value && (
        <div className={styles.requirementsContainer}>
          <h6 className={styles.requirementsTitle}>Password requirements:</h6>
          <div className={styles.requirementsList}>
            <div
              className={`${styles.requirement} ${requirements.hasUpperLower ? styles.valid : styles.invalid}`}
            >
              {getIcon(requirements.hasUpperLower)}
              <span>Include both lowercase and uppercase letters</span>
            </div>

            <div
              className={`${styles.requirement} ${requirements.hasNumber ? styles.valid : styles.invalid}`}
            >
              {getIcon(requirements.hasNumber)}
              <span>Include at least one number</span>
            </div>

            <div
              className={`${styles.requirement} ${requirements.hasSymbol ? styles.valid : styles.invalid}`}
            >
              {getIcon(requirements.hasSymbol)}
              <span>Include at least one symbol (@$!%*#?&)</span>
            </div>

            <div
              className={`${styles.requirement} ${requirements.hasLength ? styles.valid : styles.invalid}`}
            >
              {getIcon(requirements.hasLength)}
              <span>Be at least 8 characters long</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
