import { useEffect, useState } from 'react'
import styles from './../assesmentCard/assesmentCard.module.scss'
import colors from '../../styles/variables.module.scss'
import BagIcon from '../../icons/bag.icon'
import WalkingManIcon from '../../icons/walkingMan.icon'
import ClockIcon from '../../icons/clock.icon'
import CustomButton from '../shared/CustomButton/customButton'
import LiveIcon from '../../icons/live.icon'
import HideEyeIcon from '../../icons/hideEye.icon'
import {
  JobStatus,
  WorkCommitment,
  WorkType,
} from '../../services/job/job.model'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CustomModal from '../shared/customModal/customModal'
import CompanySizingModal from './companySizingModal'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

type Props = {
  id: string
  position?: string
  description?: string
  commitment?: WorkCommitment
  workType?: WorkType
  weeklyHours?: number
  taskExpertise?: string[]
  systemExpertise?: string[]
  status: JobStatus
  pageIsLoading: boolean
  orgID: string
  orgFinancialsConnected: boolean
  orgName: string
  jobStatus: string
}

const TopMatchesCard: React.FC<Props> = ({
  id,
  orgID,
  orgFinancialsConnected,
  orgName,
  position,
  description,
  commitment,
  workType,
  weeklyHours,
  taskExpertise,
  systemExpertise,
  status,
  pageIsLoading,
  jobStatus,
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [companySizing, setCompanySizing] = useState(false)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  useEffect(() => {
    const state = searchParams.get('state')
    if (state !== null) {
      try {
        const stateObj = JSON.parse(state)
        if (stateObj !== null && stateObj.id === id) {
          setCompanySizing(true)
        }
      } catch (error: any) {
        setErrorModel(true)
        setErrorMessage(error.message)
      }
    }
  }, [id, searchParams])

  return (
    <div
      className={styles.topthreeCard}
      style={{ borderColor: colors.gray200 }}
    >
      <div
        className={` justify-content-between align-items-center w-100 ${styles.displayFlex}`}
      >
        <div className={styles.tagBox}>
          <h4 className={'text-bolder text-almostBlack'}>
            {position} <p>{orgName}</p>
          </h4>
          {jobStatus === JobStatus.Inactive && (
            <div className="d-flex flex-row justify-content-between">
              <h6 className={styles.inactiveTag}>
                {jobStatus.charAt(0).toUpperCase() + jobStatus.slice(1)}
              </h6>
            </div>
          )}
        </div>
        <div
          className={
            status === JobStatus.Open
              ? styles.publishedCard
              : styles.nonPublished
          }
        >
          {status === JobStatus.Open ? (
            <LiveIcon />
          ) : (
            <HideEyeIcon color={colors.orangeColor} />
          )}
          {status === JobStatus.Open ? (
            <h6 className={'text-success ms-2'}>Live</h6>
          ) : (
            <h6 className={'text-orange ms-2'}>Unpublished</h6>
          )}
        </div>
      </div>
      <div className={'d-flex flex-wrap mt-3'}>
        {taskExpertise &&
          taskExpertise.map((item, index) => (
            <div
              className={`mt-2 mt-md-0 mb-2 ${styles.payableTag}`}
              key={index}
              id={`taskExpertise_${index}`}
            >
              <h6>{item}</h6>
            </div>
          ))}
      </div>
      <div className={`mt-5 ${styles.iconBox}`}>
        <div className={`d-flex me-1 me-lg-4 ${styles.widthCommitment}`}>
          <BagIcon />
          <h6 className={'ms-3 gray-color-text'}>
            {commitment === WorkCommitment.PermanentFullTime
              ? 'Full Time'
              : commitment === WorkCommitment.PermanentPartTime
                ? 'Part Time'
                : commitment === WorkCommitment.Casual
                  ? 'Casual'
                  : 'Unknown Commitment'}
          </h6>
        </div>
        <div className={styles.borderClass}>
          <WalkingManIcon />
          <h6 className={'ms-3 gray-color-text'}>
            {workType === WorkType.OnSite
              ? 'On Site'
              : workType === WorkType.OffSite
                ? 'Off-Site'
                : workType === WorkType.Hybrid
                  ? 'Hybrid'
                  : 'Unknown Work Type'}
          </h6>
        </div>
        <div className={`d-flex ms-2 ms-lg-4 ${styles.widthCommitment}`}>
          <ClockIcon />
          <h6 className={'ms-3 gray-color-text'}>
            {weeklyHours || '?'} Hrs per Week
          </h6>
        </div>
      </div>
      <div className={styles.Withoutdiscription}>
        <h6 className={'gray-color-text text-normal mt-5'}>{description}</h6>
        {
          // If the job status is open or pending approval, then show the view job button
          status === JobStatus.Open ||
          status === JobStatus.PendingApproval ||
          status === JobStatus.Closed ? (
            <CustomButton
              text={'View Role'}
              className={styles.whiteBtn}
              disabled={pageIsLoading}
              onClick={() =>
                navigate('/topThreeResources', { state: { jobID: id } })
              }
            />
          ) : // If the job details are incomplete, then show the complete job details button
          status === JobStatus.IncompleteDetails ? (
            <CustomButton
              text={'Complete Role Details'}
              className={styles.whiteBtn}
              disabled={pageIsLoading}
              onClick={() =>
                navigate('/jobDetails', {
                  state: {
                    jobID: id,
                    jobName: position,
                    from: 'jobs',
                    orgID: orgID,
                    orgName: orgName,
                  },
                })
              }
            />
          ) : // If the job details are complete, but no cost has been generated, and the org hasn't connected their financials,
          // and the job system expertise includes either XERO or MYOB, then show the connect accounting system button  */}
          status === JobStatus.NoCostGenerated &&
            !orgFinancialsConnected &&
            (systemExpertise?.includes('XERO') ||
              systemExpertise?.includes('MYOB')) ? (
            <CustomButton
              text={'Connect Accounting Data File'}
              className={styles.whiteBtn}
              disabled={pageIsLoading}
              onClick={() => setCompanySizing(true)}
            />
          ) : // If the job details are complete, but no cost has been generated, and the org hasn't connected their financials,
          // or the org has connected their financials but the job system expertise doesn't include either XERO or MYOB,
          // then show the complete needs analysis questionaire button
          status === JobStatus.NoCostGenerated ? (
            <CustomButton
              text={'Complete Needs Analysis'}
              className={styles.whiteBtn}
              disabled={pageIsLoading}
              onClick={() =>
                navigate('/analysis', { state: { orgID: orgID, jobID: id } })
              }
            />
          ) : (
            <p>Pending</p>
          )
        }
      </div>
      <CustomModal
        open={companySizing}
        onCloseModal={(val) => setCompanySizing(val)}
        hideCloseButton={true}
      >
        <CompanySizingModal
          closeModal={() => setCompanySizing(false)}
          orgID={orgID}
          page={'jobs'}
          jobID={id}
          availableOptions={systemExpertise ?? []}
        />
      </CustomModal>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default TopMatchesCard
