import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import FileService from '../../services/file/file.service'
import LoadingService from '../../services/loading/loading.service'
import { UploadedFile } from '../../services/file/file.model'
import CustomLayout from '../layout/commonLayout'
import styles from './../gettingStart/GettingStart.module.scss'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import CustomButton from '../shared/CustomButton/customButton'
import CustomInputField from '../shared/customInputField/CustomInputField'
import CustomDropdown from '../shared/CustomDropdown/customDropdown'
import FileUpload from '../shared/FileUpload/fileUpload'
import YearSelect from '../shared/timePicker/yearSelect'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import DollarIcon from '../../icons/dollar.icon'
import SaveIcon from '../../icons/save.icon'
import RightLongArrowIcon from '../../icons/rightLongArrow.icon'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import { Auth } from 'aws-amplify'
import LeftArrow from '../../icons/leftArrow.icon'
import CustomSearch from '../shared/listSearch/listSearch'
import JobService from '../../services/job/job.service'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'
import UserService from '../../services/user/user.service'
import Tooltip from '../shared/Tooltip/tooltip'
import CustomRangeSlider from '../shared/customRangeSlider/customRangeSlider'
import { RequestStatus } from '../../services/http/http.model'
import { WorkType } from '../../services/job/job.model'
import { MasterDataContext } from '../../context/masterData'
import { ScrollToFieldError } from '../../scrollToFieldError/scrollToFieldError'
import DefaultCloseIcon from '../../icons/defaultClose.icon'
import { matches } from '../profile/profile'
import InfoIcon from '../../icons/info.icon'
import dayjs from 'dayjs'
import CustomCalender from '../shared/calender/CustomCalender'
import OrganizationService from '../../services/organization/organization.service'

export interface JobDetailsProps {
  title?: string
  commitment?: string
  work_type?: string
  location?: string
  on_site_days?: number
  weekly_work_hours?: number
  minimum_qualification?: string
  minimum_years_of_experience?: number
  pay_type?: PayType
  pay_rate?: number
  start_date?: string
  skills?: { value: string; type: string }[]
  document?: UploadedFile
  role_start_date_type?: string
  category?: string
  taskExpertise?: any[]
  systemExpertise?: any[]
}

enum PayType {
  Hourly = 'hourly',
  Annual = 'annual',
}

const onsiteDay = [
  { value: 1, text: '1 Day' },
  { value: 2, text: '2 Days' },
  { value: 3, text: 'More than 2 days' },
]

const dateOptions = [
  { value: new Date().toISOString(), text: 'Immediate' },
  {
    value: new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 7
    ).toISOString(),
    text: 'One week',
  },
  {
    value: new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 7 * 2
    ).toISOString(),
    text: 'Two weeks',
  },
  {
    value: new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 7 * 3
    ).toISOString(),
    text: 'Three weeks',
  },
  {
    value: new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 7 * 4
    ).toISOString(),
    text: 'One month',
  },
  { value: '', text: 'Specific Date' },
]

const JobDetails = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dragItem = useRef(0)
  const dragOverItem = useRef(0)
  const masterData = useContext(MasterDataContext)
  const [isLoading, setIsLoading] = useState(false)

  const [from, setFrom] = useState<string>('organizationDetails')
  const [orgID, setOrgID] = useState<string | null>(null)
  const [orgName, setOrgName] = useState<string | null>(null)
  const [jobID, setJobID] = useState<string | null>(null)
  const [jobName, setJobName] = useState<string | null>(null)
  const [formData, setFormData] = useState<JobDetailsProps>({})
  const [qualifications, setQualifications] = useState<any[]>([])
  const [jobCategory, setJobCategory] = useState<string[]>([])

  const [systemExpertiseInput, setSystemExpertiseInput] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [navigateAfterError, setNavigateAfterError] = useState<string | null>(
    null
  )
  // Related to tags.
  const [taskExpertiseSuggestions, setTaskExpertiseSuggestions] = useState<
    string[]
  >([])
  const [systemExpertiseSuggestions, setSystemExpertiseSuggestions] = useState<
    string[]
  >([])
  const [taskExpertiseError, setTaskExpertiseError] = useState<string>('')
  const [systemExpertiseError, setSystemExpertiseError] = useState<string>('')
  const [taskTag, setTaskTag] = useState<any[]>([])
  const [systemTags, setSystemTaskTag] = useState<any[]>([])
  const [taskExpertiseInput, setTaskExpertiseInput] = useState('')
  const [errorModel, setErrorModel] = useState(false)
  useEffect(() => {
    if (masterData?.masterData) {
      setTaskExpertiseSuggestions(
        masterData.masterData
          .filter((fd: any) => fd.category === 'tasks_expertise')
          .sort((a: any, b: any) => a.order - b.order)
          .map((item: any) => item.value)
      )
      setSystemExpertiseSuggestions(
        masterData.masterData
          .filter((fd: any) => fd.category === 'systems_expertise')
          .sort((a: any, b: any) => a.order - b.order)
          .map((item: any) => item.value)
      )
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.')
      }
    }
  }, [masterData])

  function addTaskExpertise() {
    if (
      taskExpertiseInput.trim() !== '' &&
      taskTag.find((exp) => exp === taskExpertiseInput) === undefined
    ) {
      setTaskTag((prev) => [...prev, taskExpertiseInput])
    } else {
      setTaskExpertiseError('Please enter a valid task expertise')
      setTaskExpertiseInput('')
    }
    setTaskExpertiseInput('')
    return [...taskTag, taskExpertiseInput]
  }
  const dragStartTask = (e: any, position: any) => {
    dragItem.current = position
  }
  const dragEnterTask = (e: any, position: any) => {
    dragOverItem.current = position
  }

  const dropTask = () => {
    const copyListItems = [...taskTag]
    const dragItemContent = copyListItems[dragItem.current]

    copyListItems.splice(dragItem.current, 1)
    copyListItems.splice(dragOverItem.current, 0, dragItemContent)

    dragItem.current = 0
    dragOverItem.current = 0
    setTaskTag(copyListItems)
  }
  const touchTask = () => {
    const copyListItems = [...taskTag]
    if (dragItem.current != 0) {
      const dragItemContent = copyListItems[dragItem.current]
      const temp = copyListItems[dragItem.current - 1]
      copyListItems[dragItem.current - 1] = dragItemContent
      copyListItems[dragItem.current] = temp
    }
    setTaskTag(copyListItems)
  }
  function removeTaskExpertise(tag: string) {
    setTaskTag((prev) => prev.filter((exp) => exp !== tag))
  }
  function handleSystemDelete(tag: string) {
    setSystemTaskTag((prev) => prev.filter((exp) => exp !== tag))
  }

  function handleSystemAddition() {
    if (
      systemExpertiseInput.trim() !== '' &&
      systemTags.find((exp) => exp === systemExpertiseInput) === undefined
    ) {
      setSystemTaskTag((prev) => [...prev, systemExpertiseInput])
    } else {
      setSystemExpertiseError('Please enter a valid system expertise')
      setSystemExpertiseInput('')
    }
    setSystemExpertiseInput('')
    return [...systemTags, systemExpertiseInput]
  }
  const dragStartSystem = (e: any, position: any) => {
    dragItem.current = position
  }
  const dragEnterSystem = (e: any, position: any) => {
    dragOverItem.current = position
  }

  const dropSystem = () => {
    const copyListItems = [...systemTags]
    const dragItemContent = copyListItems[dragItem.current]
    copyListItems.splice(dragItem.current, 1)
    copyListItems.splice(dragOverItem.current, 0, dragItemContent)
    dragItem.current = 0
    dragOverItem.current = 0
    setSystemTaskTag(copyListItems)
  }
  const touchSystem = () => {
    const copyListItems = [...systemTags]
    if (dragItem.current != 0) {
      const dragItemContent = copyListItems[dragItem.current]
      const temp = copyListItems[dragItem.current - 1]
      copyListItems[dragItem.current - 1] = dragItemContent
      copyListItems[dragItem.current] = temp
    }
    setSystemTaskTag(copyListItems)
  }
  const initialVal: JobDetailsProps = useMemo(
    () => ({
      title: formData.title,
      commitment: formData.commitment,
      work_type: formData.work_type,
      location: formData.location,
      on_site_days: formData.on_site_days,
      weekly_work_hours: formData.weekly_work_hours,
      minimum_qualification: formData.minimum_qualification,
      minimum_years_of_experience: formData.minimum_years_of_experience,
      pay_type: formData.pay_type ?? PayType.Annual,
      pay_rate: formData.pay_rate,
      start_date: formData.start_date ?? '01/22/2023',
      role_start_date_type: formData.role_start_date_type,
      skills: formData.skills,
      document: formData.document,
      category: formData.category ?? '',
      taskExpertise: formData.taskExpertise,
      systemExpertise: formData.systemExpertise,
    }),
    [formData]
  )

  const fileService: FileService = useMemo(() => {
    return new FileService()
  }, [])

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  const jobService: JobService = useMemo(() => {
    return new JobService()
  }, [])

  const userService: UserService = useMemo(() => {
    return new UserService()
  }, [])

  const orgService: OrganizationService = useMemo(() => {
    return new OrganizationService()
  }, [])

  // Fetching the job details
  // We need to know which job to edit or which organization to post the job to.
  useEffect(() => {
    if (location.state) {
      // Location state is present, try to use it
      if (location.state.from) {
        setFrom(location.state.from)
      }
      if (location.state.orgID && location.state.jobID) {
        setOrgID(location.state.orgID)
        setOrgName(location.state.orgName)
        setJobID(location.state.jobID)
        setJobName(location.state.jobName)
        return
      }

      if (location.state.jobID && location.state.jobName) {
        setJobID(location.state.jobID)
        setJobName(location.state.jobName)
        return
      }
      if (location.state.orgID && location.state.orgName) {
        setOrgID(location.state.orgID)
        setOrgName(location.state.orgName)
        return
      }
    } else {
      const fetchData = async () => {
        try {
          const res: any = await loadingService.await(orgService.getOrgData())
          setOrgID(res.result.id)
          setOrgName(res.result.name)
        } catch (error: any) {
          setErrorModel(true)
          setErrorMessage(error.message)
        }
      }
      fetchData()
      setNavigateAfterError('/jobs')
    }
  }, [loadingService, location.state, navigate, orgService])

  // When the job ID is available or has changed, fetch the job details
  useEffect(() => {
    if (!jobID) {
      return
    }
    // Wrap this call around both loading services, as the task spinner should also be shown until the job details are fetched
    loadingService
      .await(jobService.getJob({ id: jobID }))
      .then(async (res) => {
        if (
          res === null ||
          res.status !== RequestStatus.Success ||
          res.result === null
        ) {
          setErrorMessage('Error while fetching job details')
          setNavigateAfterError('/')
          return
        }

        // Get the job description file
        const fileRes: any = await loadingService.await(
          jobService.getJobDescription({ id: jobID })
        )
        let document
        if (
          fileRes !== null &&
          fileRes.status === RequestStatus.Success &&
          fileRes.result !== null &&
          fileRes.result.key !== 'NoKey'
        ) {
          document =
            (await fileService.readBackendFile(fileRes.result)) ?? undefined
        }

        // Set the form data
        setFormData({
          title: res.result.title ?? '',
          commitment: res.result.commitment ?? '',
          work_type: res.result.work_type ?? '',
          location: res.result.location ?? '',
          on_site_days: res.result.on_site_days ?? 0,
          weekly_work_hours: res.result.weekly_work_hours ?? 0,
          minimum_qualification: res.result.minimum_qualification ?? '',
          minimum_years_of_experience:
            res.result.minimum_years_of_experience ?? 0,
          pay_type: res.result.pay_hourly ? PayType.Hourly : PayType.Annual,
          pay_rate: res.result.pay_hourly
            ? (res.result.pay_hourly ?? 0)
            : (res.result.pay_annual ?? 0),
          start_date: res.result.start_date ?? '',
          role_start_date_type: res.result.role_start_date_type ?? '',
          category: res.result.category ?? '',
          skills: res.result.skills ?? [],
          document: document,
          systemExpertise:
            res.result.skills
              .filter((value) => value.type === 'system_expertise')
              .map((val) => val.value) ?? [],
          taskExpertise: res.result.skills
            .filter((value) => value.type === 'task_expertise')
            .map((value) => value.value),
        })

        // Set the expertise tags
        setTaskTag(
          res.result.skills
            .filter((value) => value.type === 'task_expertise')
            .map((val) => val.value) ?? []
        )
        setSystemTaskTag(
          res.result.skills
            ? res.result.skills
                .filter((value) => value.type === 'system_expertise')
                .map((value) => value.value)
            : []
        )
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [fileService, jobID, jobService, loadingService])

  useEffect(() => {
    if (masterData?.masterData) {
      setTaskExpertiseSuggestions(
        masterData.masterData
          .filter((fd: any) => fd.category === 'tasks_expertise')
          .sort((a: any, b: any) => a.order - b.order)
          .map((item: any) => item.value)
      )
      setSystemExpertiseSuggestions(
        masterData.masterData
          .filter((fd: any) => fd.category === 'systems_expertise')
          .sort((a: any, b: any) => a.order - b.order)
          .map((item: any) => item.value)
      )
      const valuesToRemove = [
        'Cert IV',
        'Bachelor',
        'Bachelors',
        'Bachelors Degree',
        'Master',
        'Masters',
        'Master Degree',
      ]
      let allDataSet = masterData.masterData.filter(
        (fd: any) => fd.category === 'qualification'
      )
      allDataSet = allDataSet.filter(
        (item: any) => !valuesToRemove.includes(item.value)
      )
      setQualifications(allDataSet)
      setJobCategory(
        masterData.masterData
          .filter((fd: any) => fd.category === 'finance_jobs')
          .sort((a: any, b: any) => a.order - b.order)
      )
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.')
      }
    }
  }, [masterData])

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Role title is required'),
    commitment: Yup.string().required('Please select employment basis type'),
    work_type: Yup.string().required('Please select role type'),
    location: Yup.string().required('Role suburb is required'),
    category: Yup.string().required('Role category is required'),
    on_site_days: Yup.number().when('work_type', {
      is: (work_type: string) => work_type === 'Hybrid',
      then: Yup.number()
        .positive('Please select one of the options')
        .required('Please select one of the options'),
    }),
    weekly_work_hours: Yup.number()
      .positive('Role hours per week is required')
      .required('Role hours per week is required'),
    minimum_qualification: Yup.string()
      .notOneOf([''], 'Please select required education level')
      .required('Please select required education level'),
    minimum_years_of_experience: Yup.number()
      .notOneOf([0], 'Please select required experience')
      .required('Please select required experience'),
    pay_type: Yup.string().required('Please select pay type'),
    pay_rate: Yup.string()
      .required('Current role cost is required')
      .matches(/^[^0]/, 'Invalid rate')
      .matches(/^[0-9]+$/, 'Invalid rate')
      .typeError('Please enter a valid rate'),
    start_date: Yup.string().required('Start date is required'),
    taskExpertise: Yup.array()
      .of(Yup.string()) // Ensure each element in the array is a string
      .min(1, 'At least one tag is required') // Validate minimum array length
      .required('This field is required'),
    systemExpertise: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one tag is required') // Validate minimum array length
      .max(1, 'Only one System Expertise tag can be selected for a job')
      .test(
        'value-check',
        'You have already selected a system expertise. Therefore, you cannot select another system expertise.',
        async function (value: any) {
          const jobs = await jobService.getOrgJobs({
            id: location.state.orgID ?? '',
          })
          const previousVal = jobs.result[0]?.skills.filter(
            (fv: any) => fv.type == 'system_expertise'
          )[0]?.value
          if (!previousVal) {
            return true // No error if one or fewer tags are selected
          } else {
            if (value && value?.length !== 0) {
              if (value[0] === previousVal) {
                return true
              } else {
                throw new Yup.ValidationError(
                  'You have already selected ' +
                    previousVal +
                    ' as the system expertise for your organization. Therefore you cannot select another system expertise. ',
                  value,
                  'systemExpertise'
                )
              }
            } else {
              return true // No error if one or fewer tags are selected
            }
          }
        }
      )
      .required('This field is required'),
    role_start_date_type: Yup.string().required('This field is required'),
    document: Yup.mixed().required('File is required'),
  })

  /**
   * Set a formik file value. If the file is larger than 10MB, set an error.
   * @param key key of the form value.
   * @param value the file.
   * @param setFieldValue formik setFieldValue function.
   * @param setFieldError formik setFieldError function.
   * @returns void.
   */
  const setFileValue = (
    key: string,
    value: UploadedFile,
    setFieldValue: FormikHelpers<JobDetailsProps>['setFieldValue'],
    setFieldError: FormikHelpers<JobDetailsProps>['setFieldError']
  ): void => {
    if (value.original) {
      if (value.original.size > 10 * 1024 * 1024) {
        setFieldError(key, 'File is larger than 10MB.')
        return
      }
    }
    setFieldValue(key, value)
  }

  /**
   * Save the form values to the backend.
   * @param values the form values.
   * @returns true if the form values were saved successfully, false otherwise.
   */
  const handleSave = async (values: JobDetailsProps): Promise<any> => {
    // Put the expertise tags into the correct format.
    const tasksExp = taskTag.map((value) => {
      return {
        value: value,
        type: 'task_expertise',
      }
    })
    const systemsExp = systemTags.map((value) => {
      return {
        value: value,
        type: 'system_expertise',
      }
    })
    const skills = tasksExp
      .concat(systemsExp)
      .filter((_tag, index, array) => array[index].value !== '')

    // Put the form values into the correct format.
    // Include only the values that are not empty.
    const params = {
      ...(values.title ? { title: values.title } : {}),
      ...(values.commitment ? { commitment: values.commitment } : {}),
      ...(values.work_type ? { work_type: values.work_type } : {}),
      ...(values.location ? { location: values.location } : {}),
      ...(values.on_site_days ? { on_site_days: values.on_site_days } : {}),
      ...(values.weekly_work_hours
        ? { weekly_work_hours: values.weekly_work_hours }
        : {}),
      ...(values.minimum_qualification
        ? { minimum_qualification: values.minimum_qualification }
        : {}),
      ...(values.minimum_years_of_experience !== undefined
        ? { minimum_years_of_experience: values.minimum_years_of_experience }
        : {}),
      ...(values.pay_type === PayType.Annual && {
        pay_annual: values.pay_rate,
      }),
      ...(values.pay_type === PayType.Hourly && {
        pay_hourly: values.pay_rate,
      }),
      ...(skills.length > 0 ? { skills: skills } : {}),
      ...(values.start_date ? { start_date: values.start_date } : {}),
      ...(values.role_start_date_type
        ? { role_start_date_type: values.role_start_date_type }
        : {}),
      ...(values.category ? { category: values.category } : {}),
    }

    // Save the job data.
    // Either post a new job or edit an existing job.
    let res = null
    if (jobID !== null) {
      res = await loadingService.await(
        jobService.editJob({ id: jobID }, params)
      )
    } else if (orgID !== null) {
      res = await loadingService.await(
        jobService.postJob({ id: orgID }, params)
      )
    }
    if (res === null || res.status !== RequestStatus.Success) {
      return { jobCreateError: true }
    }

    // Upload the job description file.
    if (values.document) {
      const fileRes = await loadingService.await(
        jobService.uploadJobDescription(
          { id: jobID ?? res.result.id },
          values.document
        )
      )
      if (fileRes === null || fileRes.status !== RequestStatus.Success) {
        return { fileUploadError: true }
      }
    }

    // Set the job ID and name state values.
    setJobID(res.result.id)
    setJobName(res.result.title ?? 'Untitled Job')

    return { jobCreateError: false }
  }

  /**
   * Saves the job data and navigates to the job details page
   * @param values the form values
   * @returns void
   */
  const handleSubmit = async (values: JobDetailsProps): Promise<void> => {
    const res = await handleSave(values)
    if (res.jobCreateError) {
      setErrorMessage('Failed to save job.')
      return
    } else if (res.fileUploadError) {
      setErrorMessage(
        'Sorry we are unable to generate a role description from the file that you uploaded. Please try adding another role description file by clicking Complete Role Details from the Roles page'
      )
      setNavigateAfterError('/jobs')
      return
    }
    await loadingService.await(userService.updateLastPage('jobs'))
    navigate('/')
  }

  /**
   * Saves the job data, then logs out the user and navigates to the sign in page
   * @param values the form values
   * @returns void
   */
  const handleSaveAndExit = async (values: JobDetailsProps): Promise<void> => {
    const res = await handleSave(values)
    if (res.jobCreate) {
      setErrorMessage('Failed to save job.')
      return
    } else if (res.fileUpload) {
      setErrorMessage('Failed to Extract role description.')
      return
    }
    await loadingService.await(userService.updateLastPage('jobs'))
    localStorage.removeItem('masterData')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('tokenExpiration')
    localStorage.removeItem('login_form')
    await loadingService.await(Auth.signOut())
    navigate('/signIn?type=organizationSignIn')
  }

  /**
   * Opens a new tab with the role description PDF
   * @param document the uploaded file (optional)
   * @returns void
   */
  const openCoverLetterPDFInNewTab = async (
    document?: UploadedFile
  ): Promise<void> => {
    if (jobID) {
      // If the job ID is not null, get the presigned URL for the job description file.
      const url: any = await jobService.getPreSignRoleDescFile({ id: jobID })
      const pdfUrl = url.result
      window.open(pdfUrl, '_blank')
    } else if (document) {
      // If the job ID is null, open the uploaded file.
      fileService.openFile(document)
    }
  }
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      {isLoading && (
        <center>
          <div>
            <LoadingSpinner />
          </div>
        </center>
      )}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <ErrorBannerModal
          open={errorMessage !== ''}
          onClose={() => {
            if (navigateAfterError !== null) {
              navigate(navigateAfterError)
            } else {
              setErrorMessage('')
            }
          }}
          errorMessage={errorMessage}
        />
        <CustomLayout
          organization
          title={
            jobName
              ? `Role Details (Editing ${jobName})`
              : `Role Details (Hiring for ${orgName})`
          }
          subTitle={`Tell us about the role you’re hiring for`}
          pageName={'roleDetails'}
          progressValue={60}
        >
          <Formik
            initialValues={initialVal}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validateOnChange
          >
            {({ values, setFieldValue, errors, touched, setFieldError }) => (
              <Form>
                <ScrollToFieldError />
                <div
                  className={styles.contentQuestion}
                  style={{ position: 'relative' }}
                >
                  <h6 className={styles.widthLeft}>Role information</h6>
                  <div
                    className={styles.answerLength}
                    style={{ position: 'relative' }}
                  >
                    <CustomInputField
                      name={'title'}
                      placeholder={'Role Title *'}
                      onChange={(e: any) =>
                        setFieldValue('title', e.target.value)
                      }
                    />

                    <div
                      className={'w-100 mt-3'}
                      style={{ position: 'relative' }}
                    >
                      <CustomSearch
                        name={'location'}
                        placeholder="Role Location (Suburb) *"
                        endPoint={(value) =>
                          'location/autocomplete?' +
                          new URLSearchParams({ input: value })
                        }
                        onChange={(value: string) =>
                          setFieldValue('location', value)
                        }
                        defaultValue={values.location}
                        timeout={1000}
                      />
                      {touched.location && errors.location && (
                        <div
                          className={styles.yupError}
                          style={{ position: 'relative', top: '0px' }}
                        >
                          {errors.location}
                        </div>
                      )}
                    </div>
                    <div
                      className={'w-100 mt-3'}
                      style={{ position: 'relative' }}
                    >
                      <CustomDropdown
                        name={'category'}
                        placeHolder={'Role Category *'}
                        dataList={jobCategory.map((value: any) => ({
                          text: value.value,
                          value: value.id,
                        }))}
                        getSelectedItem={(i) => {
                          setFieldValue('category', i.value)
                        }}
                        selectedValue={
                          jobCategory
                            .filter((fd: any) => fd.id === values?.category)
                            .map((md: any) => ({
                              text: md.value,
                              value: md.id,
                            }))[0]
                        }
                      />
                      {touched.category && errors.category && (
                        <div
                          className={styles.yupError}
                          style={{ position: 'relative', top: '0px' }}
                        >
                          {errors.category}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={styles.contentQuestion}
                  style={{ position: 'relative' }}
                >
                  <h6 className={styles.widthLeft}>Required experience</h6>
                  <div
                    className={styles.answerLength}
                    style={{ position: 'relative' }}
                  >
                    <YearSelect
                      name={'minimum_years_of_experience'}
                      id={'Required experience'}
                      handleChange={(e) =>
                        setFieldValue(
                          'minimum_years_of_experience',
                          parseInt(e)
                        )
                      }
                      defaultValue={values.minimum_years_of_experience ?? 0}
                    />
                    {touched.minimum_years_of_experience &&
                      errors.minimum_years_of_experience && (
                        <div
                          className={styles.yupError}
                          style={{ position: 'relative', top: '0px' }}
                        >
                          {errors.minimum_years_of_experience}
                        </div>
                      )}
                  </div>
                </div>
                <div className={styles.contentQuestion}>
                  <h6 className={styles.widthLeft}>Employment basis</h6>
                  <div
                    className={`d-flex justify-content-between ${styles.answerLengthNew}`}
                    style={{ position: 'relative' }}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="commitment"
                        id="commitment1"
                        value="Permanent Full Time"
                        checked={values.commitment === 'Permanent Full Time'}
                        onChange={(e) =>
                          setFieldValue('commitment', e.target.value)
                        }
                      />
                      <label className="form-check-label" htmlFor="commitment1">
                        <h6 className={'gray-color-text text-normal'}>
                          Full time
                        </h6>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="commitment"
                        id="commitment2"
                        value="Permanent Part Time"
                        checked={values.commitment === 'Permanent Part Time'}
                        onChange={(e) =>
                          setFieldValue('commitment', e.target.value)
                        }
                      />
                      <label className="form-check-label" htmlFor="commitment2">
                        <h6 className={'gray-color-text text-normal'}>
                          Part time
                        </h6>
                      </label>
                    </div>
                    {touched.commitment && errors.commitment && (
                      <div className={`${styles.yupError} mt-2`}>
                        {errors.commitment}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={styles.contentQuestion}
                  style={{ position: 'relative' }}
                >
                  <h6 className={styles.widthLeft}>Role hours per week</h6>
                  <div
                    className={styles.answerLength}
                    style={{ position: 'relative' }}
                  >
                    <CustomRangeSlider
                      name={'weekly_work_hours'}
                      min={0}
                      max={40}
                      step={1}
                      defaultValue={`${values.weekly_work_hours ?? 0}`}
                      onClickStep={(s) => {
                        setFieldValue('weekly_work_hours', parseInt(s))
                      }}
                    />
                    {touched.weekly_work_hours && errors.weekly_work_hours && (
                      <div className={styles.yupError} style={{ top: '40px' }}>
                        {errors.weekly_work_hours}
                      </div>
                    )}
                  </div>
                  <br />
                  <br />
                </div>
                <div className={styles.contentQuestion}>
                  <div className={styles.widthLeft}>
                    <h6>Current role cost</h6>
                    <p className={'gray-color-text text-normal'}>
                      excluding super - no commas or decimal places please
                    </p>
                  </div>

                  <div
                    className={`d-flex justify-content-between ${styles.answerLength}`}
                  >
                    <div style={{ position: 'relative' }}>
                      <CustomInputField
                        name={'pay_rate'}
                        className={styles.widthInput}
                        icon={<DollarIcon size={16} />}
                        defaultValue={values.pay_rate ?? ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue('pay_rate', e.target.value)
                        }
                      />
                    </div>
                    <div
                      className={`d-flex justify-content-between align-items-center w-50 `}
                      style={{ position: 'relative' }}
                    >
                      <div className={'d-flex align-items-center'}>
                        <div className="form-check me-0 me-lg-4  ms-2 ms-lg-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="pay_type"
                            id="pay_type1"
                            value="annual"
                            checked={values.pay_type === PayType.Annual}
                            onChange={(e) =>
                              setFieldValue('pay_type', e.target.value)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="pay_type1"
                          >
                            <h6 className={'text-normal gray-color-text me-2'}>
                              Annual
                            </h6>
                          </label>
                        </div>
                        <div>
                          <Tooltip
                            body={
                              'To calculate the FTE equivalent, please use a 40-hour week (8-hour day) as the basis of your calculation, for example, if you currently pay $60,000 excluding super for 3 x 8/hr days per week, the annual figure will be $100,000 ($60K ÷ 3days x 5 days)'
                            }
                          />
                        </div>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="pay_type"
                          id="pay_type2"
                          value="hourly"
                          checked={values.pay_type === PayType.Hourly}
                          onChange={(e) =>
                            setFieldValue('pay_type', e.target.value)
                          }
                        />
                        <label className="form-check-label" htmlFor="pay_type2">
                          <h6 className={'text-normal gray-color-text'}>
                            Hourly
                          </h6>
                        </label>
                      </div>

                      {touched.pay_type && errors.pay_type && (
                        <div
                          className={`${styles.yupError} `}
                          style={{ marginTop: '20px' }}
                        >
                          {errors.pay_type}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={styles.contentQuestion}
                  style={{ position: 'relative' }}
                >
                  <h6 className={styles.widthLeft}>
                    When do you need the role to start
                  </h6>
                  <div
                    className={styles.answerLength}
                    style={{ position: 'relative' }}
                  >
                    <div>
                      {dateOptions.map(
                        (
                          option: { value: string; text: string },
                          index: number
                        ) => (
                          <div className="form-check" key={index}>
                            <input
                              className="form-check-input"
                              type="radio"
                              value={option.value}
                              name="start_date"
                              id={'role_start_date_type' + index}
                              checked={
                                values.role_start_date_type === option.text ||
                                values.start_date === option.value
                              }
                              onChange={() => {
                                setFieldValue('start_date', option.value)
                                setFieldValue(
                                  'role_start_date_type',
                                  option.text
                                )
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={'role_start_date_type' + index}
                            >
                              <h6 className={'text-normal gray-color-text'}>
                                {option.text}
                              </h6>
                            </label>
                          </div>
                        )
                      )}
                    </div>
                    {touched.start_date && errors.start_date && (
                      <div
                        className={`${styles.error} `}
                        style={{ marginTop: '20px' }}
                      >
                        {errors.start_date}
                      </div>
                    )}

                    {/* Show the specific date option if the start_date value is '' (dateOptions value for Specific Date)
                        or if the start_date value is a specific date (not undefined, and doesn't match any of the dateOptions) */}
                    {values.role_start_date_type === 'Specific Date' && (
                      <CustomCalender
                        icon
                        dateSelect
                        SelectYear={new Date(values.start_date ?? '')}
                        getSelectedYear={(data) => {
                          setFieldValue(
                            'start_date',
                            dayjs(data).format('YYYY-MM-DD')
                          )
                        }}
                      />
                    )}
                    {touched.role_start_date_type &&
                      errors.role_start_date_type && (
                        <div
                          className={styles.yupError}
                          style={{ top: '170px' }}
                        >
                          {errors.role_start_date_type}
                        </div>
                      )}
                  </div>
                </div>
                <div className={styles.contentQuestion}>
                  <h6 className={styles.widthLeft}>Preferred role type?</h6>
                  <div className={styles.answerLength}>
                    <div
                      className={'d-flex justify-content-between'}
                      style={{ position: 'relative' }}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="work_type"
                          id="work_type3"
                          value={WorkType.Hybrid}
                          checked={values.work_type === WorkType.Hybrid}
                          onChange={(e) => {
                            setFieldValue('work_type', e.target.value)
                            setFieldValue('on_site_days', 0)
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="work_type3"
                        >
                          <h6 className={'text-normal gray-color-text'}>
                            Hybrid
                          </h6>
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="work_type"
                          id="work_type1"
                          value={WorkType.OnSite}
                          checked={values.work_type === WorkType.OnSite}
                          onChange={(e) => {
                            setFieldValue('work_type', e.target.value)
                            setFieldValue('on_site_days', 0)
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="work_type1"
                        >
                          <h6 className={'text-normal gray-color-text'}>
                            Onsite
                          </h6>
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="work_type"
                          id="work_type2"
                          value={WorkType.OffSite}
                          checked={values.work_type === WorkType.OffSite}
                          onChange={(e) => {
                            setFieldValue('work_type', e.target.value)
                            setFieldValue('on_site_days', 0)
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="work_type2"
                        >
                          <h6 className={'text-normal gray-color-text'}>
                            Offsite
                          </h6>
                        </label>
                      </div>
                      {touched.work_type && errors.work_type && (
                        <div className={`${styles.yupError} mt-2`}>
                          {errors.work_type}
                        </div>
                      )}
                    </div>
                    {values.work_type === WorkType.Hybrid && (
                      <div className={`mt-4 ${styles.resumeUploadBox}`}>
                        <h6 className={'gray-color-text mt-4'}>
                          How many days do you require the position to work on
                          site?
                        </h6>
                        <div
                          className={styles.locationAnswer}
                          style={{ position: 'relative' }}
                        >
                          {onsiteDay.map((value, index) => (
                            <div className="form-check mt-4" key={index}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="on_site_days"
                                id={'on_site_days' + index}
                                value={value.value}
                                checked={values.on_site_days === value.value}
                                onChange={(e) =>
                                  setFieldValue(
                                    'on_site_days',
                                    parseInt(e.target.value)
                                  )
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={'on_site_days' + index}
                              >
                                <h6
                                  className={'text-normal gray-color-text me-4'}
                                >
                                  {value.text}
                                </h6>
                              </label>
                            </div>
                          ))}
                          {touched.on_site_days && errors.on_site_days && (
                            <div
                              className={`${styles.yupError} mb-3 mt-4`}
                              style={{ top: '25px' }}
                            >
                              {errors.on_site_days}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={styles.contentQuestion}
                  style={{ position: 'relative' }}
                >
                  <h6 className={styles.widthLeft}>
                    Required Australian education level
                  </h6>
                  <div
                    className={styles.answerLength}
                    style={{ position: 'relative' }}
                  >
                    <div className={'w-100'}>
                      <CustomDropdown
                        name={'education'}
                        placeHolder={'Education *'}
                        dataList={qualifications.map((value: any) => ({
                          text: value.value,
                          value: value.id,
                        }))}
                        getSelectedItem={(i) => {
                          setFieldValue('minimum_qualification', i.value)
                        }}
                        selectedValue={
                          qualifications
                            .filter(
                              (fd: any) =>
                                fd.id === values?.minimum_qualification
                            )
                            .map((md: any) => ({
                              text: md.value,
                              value: md.id,
                            }))[0]
                        }
                      />
                      {touched.minimum_qualification &&
                        errors.minimum_qualification && (
                          <div
                            className={styles.yupError}
                            style={{ position: 'relative', top: '0px' }}
                          >
                            {errors.minimum_qualification}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <h6 className={'gray-color-text mt-3 text-semi-bold mb-3'}>
                  Please review and edit the Task, System and Industry expertise
                  we have uploaded from your resume or LinkedIn Profile. Start
                  typing to add what we already have as a tag or list your own.
                  To ensure that the strongest area of expertise is rated more
                  highly, please ‘drag and drop’ the tags in each section to
                  ensure that your top three areas where you shine are at the
                  top of the list{' '}
                </h6>
                <div className={styles.contentQuestion}>
                  <h6 className={styles.widthLeft}>Task expertise</h6>
                  <div className={styles.answerLength}>
                    <div>
                      <div className={styles.bottomContent}>
                        <div className={styles.searchBar}>
                          <CustomDropdown
                            placeHolder={'New task expertise'}
                            dataList={taskExpertiseSuggestions
                              .filter((e) => matches(e, taskExpertiseInput))
                              .map((e) => ({
                                text: e,
                                value: e,
                              }))}
                            onChange={(e: string) => {
                              setTaskExpertiseInput(e)
                              setTaskExpertiseError('')
                            }}
                            getSelectedItem={(item) => {
                              setTaskExpertiseInput(item.value)
                              setTaskExpertiseError('')
                            }}
                            selectedValue={{
                              text: taskExpertiseInput,
                              value: taskExpertiseInput,
                            }}
                            sortDropdown={true}
                          />
                        </div>
                        <input
                          name={'taskExpertise'}
                          type={'hidden'}
                          value={taskTag}
                        />
                        <CustomButton
                          text={'Add Task Expertise'}
                          className={`mt-3 mt-md-0 ${styles.addButtonFixWidth}`}
                          onClick={() => {
                            setFieldValue('taskExpertise', addTaskExpertise())
                          }}
                          disabled={
                            isLoading || taskExpertiseInput.length === 0
                          }
                        />
                      </div>
                      {taskExpertiseError.trim() !== '' && (
                        <div
                          className={styles.yupError}
                          style={{
                            marginTop: '-16px',
                            position: 'relative',
                            marginBottom: '22px',
                          }}
                        >
                          {taskExpertiseError}
                        </div>
                      )}
                      <div
                        className={styles.tagBox}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        {taskTag.length > 0 && (
                          <h6 className={'text-normal mt-3'}>
                            Selected Skills
                          </h6>
                        )}
                        <div
                          className={
                            'd-flex align-items-center w-100 flex-wrap'
                          }
                        >
                          {taskTag.map((exp: string, index) => (
                            <div
                              key={exp}
                              className={`me-3 ${styles.payableTag}`}
                              draggable={true}
                              onDragStart={(e) => dragStartTask(e, index)}
                              onDragEnter={(e) => dragEnterTask(e, index)}
                              onDragEnd={dropTask}
                              onTouchStart={(e) => dragStartTask(e, index)}
                              onTouchMove={(e) => dragEnterTask(e, index)}
                              onTouchEnd={touchTask}
                            >
                              {exp}
                              <div
                                className={styles.tagDelete}
                                onClick={() => {
                                  removeTaskExpertise(exp)
                                  setFieldValue(
                                    'taskExpertise',
                                    taskTag.filter((fd: any) => fd !== exp)
                                      ? taskTag.filter((fd: any) => fd !== exp)
                                      : []
                                  )
                                }}
                              >
                                <DefaultCloseIcon />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {touched.taskExpertise && errors.taskExpertise && (
                        <div
                          className={styles.yupError}
                          style={{ position: 'relative', top: '0px' }}
                        >
                          {errors.taskExpertise}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.contentQuestion}>
                  <h6 className={styles.widthLeft}>System expertise</h6>
                  <div className={styles.answerLength}>
                    <div>
                      <div className={styles.bottomContent}>
                        <div className={styles.searchBar}>
                          <CustomDropdown
                            placeHolder={'New system expertise'}
                            dataList={systemExpertiseSuggestions
                              .filter((e) => matches(e, systemExpertiseInput))
                              .map((e) => ({
                                text: e,
                                value: e,
                              }))}
                            onChange={(e: string) => {
                              setSystemExpertiseInput(e)
                              setSystemExpertiseError('')
                            }}
                            getSelectedItem={(item) => {
                              setSystemExpertiseInput(item.value)
                              setSystemExpertiseError('')
                            }}
                            selectedValue={{
                              text: systemExpertiseInput,
                              value: systemExpertiseInput,
                            }}
                            sortDropdown={true}
                          />
                        </div>
                        <input
                          name={'systemExpertise'}
                          type={'hidden'}
                          value={systemTags}
                        />
                        <CustomButton
                          text={'Add System Expertise'}
                          className={`mt-3 mt-md-0 ${styles.addButtonFixWidth}`}
                          onClick={() => {
                            setFieldValue(
                              'systemExpertise',
                              handleSystemAddition()
                            )
                          }}
                          disabled={
                            isLoading || systemExpertiseInput.length === 0
                          }
                        />
                      </div>
                      {systemExpertiseError.trim() !== '' && (
                        <div
                          className={styles.yupError}
                          style={{
                            marginTop: '-16px',
                            position: 'relative',
                            marginBottom: '22px',
                          }}
                        >
                          {systemExpertiseError}
                        </div>
                      )}
                      <div
                        className={styles.tagBox}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        {systemTags.length > 0 && (
                          <h6 className={'text-normal mt-3'}>
                            Selected Skills
                          </h6>
                        )}
                        <div
                          className={
                            'd-flex align-items-center w-100 flex-wrap'
                          }
                        >
                          {systemTags.map((exp: string, index) => (
                            <div
                              key={index}
                              className={`me-3 ${styles.payableTag}`}
                              draggable={true}
                              onDragStart={(e) => dragStartSystem(e, index)}
                              onDragEnter={(e) => dragEnterSystem(e, index)}
                              onDragEnd={dropSystem}
                              onTouchStart={(e) => dragStartSystem(e, index)}
                              onTouchMove={(e) => dragEnterSystem(e, index)}
                              onTouchEnd={touchSystem}
                            >
                              {exp}
                              <div
                                className={styles.tagDelete}
                                onClick={() => {
                                  handleSystemDelete(exp)
                                  setFieldValue(
                                    'systemExpertise',
                                    systemTags.filter((fd: any) => fd !== exp)
                                      ? systemTags.filter(
                                          (fd: any) => fd !== exp
                                        )
                                      : []
                                  )
                                }}
                              >
                                <DefaultCloseIcon />
                              </div>
                            </div>
                          ))}
                          {touched.systemExpertise &&
                            errors.systemExpertise && (
                              <div
                                className={styles.yupError}
                                style={{ position: 'relative', top: '0px' }}
                              >
                                {errors.systemExpertise}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={`${styles.contentQuestion}`}>
                    <h6 className={styles.widthLeft}>Role description</h6>

                    <div className={`row ${styles.answerLength}`}>
                      <FileUpload
                        disabled={isLoading}
                        id={'uploadFile'}
                        filedText={'Pick a file *'}
                        fileSelect={(file: File | null) => {
                          if (file !== null)
                            fileService
                              .readFile(file)
                              .then((value) =>
                                setFileValue(
                                  'document',
                                  value,
                                  setFieldValue,
                                  setFieldError
                                )
                              )
                        }}
                      />
                      <h6 className={'gray-color-text '}>
                        Supported files - .pdf
                      </h6>
                      <div className={styles.infoBox}>
                        <div>
                          <div className={'d-flex'}>
                            <InfoIcon />
                            <h6 className={'ms-3 text-info-color'}>
                              Information
                            </h6>
                          </div>
                          <h6 className={'text-normal text-info-color'}>
                            Please upload any document in a .pdf format that
                            gives more detail of the role you are looking to
                            fill. This can be a Role/Position Description, a
                            SEEK ad or a list of tasks as an example.{' '}
                          </h6>
                        </div>
                      </div>
                      {touched.document && values.document === undefined && (
                        <div
                          className={styles.yupError}
                          style={{ position: 'relative', top: '-5px' }}
                        >
                          Please choose a relevant file
                        </div>
                      )}
                      {values.document && (
                        <div className={`row ${styles.answerLength}`}>
                          <div className={styles.selectedResume}>
                            <h6 className={'text-bold gray-color-text'}>
                              Selected File
                            </h6>
                            <div className={`d-flex ${styles.borderTop}`}>
                              <div
                                className={
                                  'd-flex justify-content-between align-items-center w-100'
                                }
                              >
                                <h6
                                  className={
                                    'text-normal gray-color-text d-flex justify-content-between w-100'
                                  }
                                >
                                  <span className={`${styles.elipse}`}>
                                    {values.document
                                      ? values.document.name
                                      : ''}{' '}
                                  </span>
                                  <span
                                    className={styles.link}
                                    onClick={() =>
                                      openCoverLetterPDFInNewTab(
                                        values.document
                                      )
                                    }
                                  >
                                    View File
                                  </span>
                                </h6>

                                <div
                                  className={'pointer ms-2'}
                                  onClick={() =>
                                    setFieldValue('document', null)
                                  }
                                >
                                  <RecycleBinIcon />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={
                    'd-flex justify-content-between align-items-center mt-5'
                  }
                >
                  <div className={'d-flex align-items-center'}>
                    <CustomButton
                      disabled={isLoading}
                      type={'button'}
                      text={'Go Back'}
                      icon={<LeftArrow />}
                      iconSide={'left'}
                      className={styles.whiteBtn}
                      onClick={() => navigate('/' + from)}
                    />
                    <CustomButton
                      type={'button'}
                      text={'Save & Exit'}
                      disabled={isLoading}
                      icon={<SaveIcon />}
                      iconSide={'left'}
                      className={`ms-3 ${styles.outlineBtn}`}
                      onClick={() => handleSaveAndExit(values)}
                    />
                  </div>
                  <div className={'d-flex align-items-center'}>
                    <CustomButton
                      type={'submit'}
                      text={'Finish'}
                      icon={<RightLongArrowIcon size={14} />}
                      iconSide={'right'}
                      loading={isLoading}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CustomLayout>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default JobDetails
