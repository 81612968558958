import { useEffect, useMemo, useState } from 'react'
import styles from './dashboard.module.scss'
import TopNavigation from '../topNavigation/topNavigation'
import AssessmentCard from '../assesmentCard/assessmentCard'
import ProfileStatusCard from '../assesmentCard/profileStatusCard'
import TopMatchesCard from '../assesmentCard/topMatchesCard'
import { useNavigate } from 'react-router-dom'
import LoadingService from '../../services/loading/loading.service'
import UserService from '../../services/user/user.service'
import { DashboardProps } from './dashboard'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import { CandidateService } from '../../services/candidate/candidate.service'
import FileService from '../../services/file/file.service'
import NotificationBox from '../shared/notificationBox/notificationBox'
import { JobDetails, JobSkill } from '../../services/job/job.res.model'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

const Dashboard = () => {
  const navigate = useNavigate()
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [userInfoData, setUserData] = useState<any>('')
  const [values, setValues] = useState<DashboardProps>({
    email: '',
    fullName: '',
    profileStatus: 0,
    top3Matches: [],
    profileImage: null,
  })

  const [isLoading, setIsLoading] = useState(false)

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService()
  }, [])

  const userService: UserService = useMemo(() => {
    return new UserService()
  }, [])

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  const fileService: FileService = useMemo(() => {
    return new FileService()
  }, [])

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const userData: any = await userService.getUserDataT()
      setUserData(userData)
      const image = await userService.getProfileImage()
      let top3Matches: any = null
      if (userData.status !== 'inactive') {
        top3Matches = await candidateService.getTop3Matches()
      }

      setValues({
        fullName: userData ? userData.full_name : '',
        email: userData ? userData.email : '',
        profileStatus: userData ? (parseInt(userData.status) ?? 0) : 0,
        top3Matches: top3Matches ? top3Matches.result : [],
        profileImage: image ? await fileService.readBackendImage(image) : null,
      })
    }
    loadingService.await(fetchData()).catch((error) => {
      setErrorModel(true)
      setErrorMessage(error.message)
    })
  }, [navigate, loadingService, userService, candidateService, fileService])

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <div className={'w-100'}>
          <TopNavigation dashboard tabValue={'0'} />
          <div className={styles.contentTopThree}>
            {values.top3Matches.length === 0 && (
              <h3
                className={`text-almostBlack mb-5 text-bolder mt-5 mt-xl-0 ${styles.mobileView}`}
              >
                We&apos;ll be in touch with the roles we have in the next 24
                hours or call 1300 991 059
              </h3>
            )}
            <div className={styles.leftSide}>
              <div className={'mt-5'}>
                <NotificationBox />
              </div>
              <div className={'mt-5'}>
                <ProfileStatusCard
                  topThree
                  full_name={values.fullName}
                  email={values.email}
                  profilePic={values.profileImage?.contents}
                />
              </div>
              <div className={'mt-5'}>
                <AssessmentCard
                  topthree
                  softSkill
                  title={'Soft Skill Assessment'}
                  description={
                    'part of the success of any role is about ‘fit’. This assessment looks at the attributes that the best role will need to suit your personality and commas style.'
                  }
                />
              </div>
            </div>
            <div className={'mt-5 width-fit-content'}>
              {values.top3Matches.length === 0 ? (
                <h3
                  className={`text-almostBlack mb-5 text-bolder mt-5 mt-xl-0 ${styles.webView}`}
                >
                  {userInfoData.status !== 'inactive'
                    ? ' We&apos;ll be in touch with the roles we have in the next 24 hours or call 1300 991 059'
                    : 'Your account is inactive at the moment'}{' '}
                </h3>
              ) : (
                <h3
                  className={'text-almostBlack mb-5 text-bolder mt-4 mt-xl-0'}
                >
                  Top{' '}
                  {values.top3Matches.length !== 1
                    ? values.top3Matches.length
                    : ''}{' '}
                  match{values.top3Matches.length !== 1 && 'es'} for you
                </h3>
              )}
              {values.top3Matches.map((job: JobDetails, index: number) => (
                <div className={'mt-3'} key={index}>
                  {job && (
                    <TopMatchesCard
                      position={job.title ?? ''}
                      commitment={job.commitment ?? ''}
                      workType={job.work_type ?? ''}
                      hoursPerWeek={`${job.weekly_work_hours?.toString()}`}
                      taskExpertise={job.skills
                        .filter((s: JobSkill) => s.type === 'task_expertise')
                        .map((s: JobSkill) => s.value)}
                      softSkills={job.skills
                        .filter((s: JobSkill) => s.type === 'soft_skill')
                        .map((s: JobSkill) => s.value)}
                      educationRequired={job.minimum_qualification}
                      experienceRequired={job.minimum_years_of_experience}
                      startDate={job.start_date}
                      visaRequired={[
                        job.citizens_eligible ? 'Australian Citizens' : '',
                        job.permanent_residents_eligible
                          ? 'Permanent Residents'
                          : '',
                        job.visa_holders_eligible ? 'Visa Holders' : '',
                      ]
                        .filter((s: string) => s !== '')
                        .join(', ')}
                      description={job.job_description ?? ''}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default Dashboard
