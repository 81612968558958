type Actions =
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_IMAGE_TOUCHED'; payload: boolean }
  | { type: 'SET_COVER_LETTER_TOUCHED'; payload: boolean }
  | { type: 'SET_RESUME_TOUCHED'; payload: boolean }

type State = {
  isLoading: boolean
  isProfileImageTouched: boolean
  isCoverLetterTouched: boolean
  isResumeTouched: boolean
}

export const initialState: State = {
  isLoading: false,
  isProfileImageTouched: false,
  isCoverLetterTouched: false,
  isResumeTouched: false,
}

export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      }
    case 'SET_IMAGE_TOUCHED':
      return {
        ...state,
        isProfileImageTouched: action.payload,
      }
    case 'SET_COVER_LETTER_TOUCHED':
      return {
        ...state,
        isCoverLetterTouched: action.payload,
      }
    case 'SET_RESUME_TOUCHED':
      return {
        ...state,
        isResumeTouched: action.payload,
      }
    default:
      return state
  }
}
