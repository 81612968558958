import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styles from '../gettingStart/GettingStart.module.scss'
import CustomButton from '../shared/CustomButton/customButton'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import CustomInputField from '../shared/customInputField/CustomInputField'
import { Formik, Form, FormikValues } from 'formik'
import * as Yup from 'yup'
import LoadingService from '../../services/loading/loading.service'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import OrganizationService from '../../services/organization/organization.service'
import CustomDropdown from '../shared/CustomDropdown/customDropdown'
import axios from 'axios'
import { Urls } from '../../context/Urls'
import { MasterDataContext } from '../../context/masterData'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

export type CompanyDetails = {
  name: string
  abn: string
  address: string
  industry: string
  type: string
  streetName: string
  city: string
  postalCode: string
  state: string
}

type Props = {
  onCancel: (isCancelled: boolean) => void // Callback function to handle cancel event
}

const UpdateCompanyDetails: React.FC<Props> = ({ onCancel }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [errorModel, setErrorModel] = useState(false)
  const masterData = useContext(MasterDataContext)
  const [isLoading, setIsLoading] = useState(false)
  const [orgTypes, setOrgTypes] = useState<any[]>([])
  const [industryTypes, setIndustryTypes] = useState<any[]>([])
  const [inputLoc, setInputLoc] = useState('')
  const [initialVal, setInitialVal] = useState<CompanyDetails>({
    name: '',
    abn: '',
    address: '',
    industry: '',
    type: '',
    streetName: '',
    city: '',
    postalCode: '',
    state: '',
  })

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter your organisation name'),
    abn: Yup.string().length(11, 'ABN must have 11 digits'),
    address: Yup.string().required('Please enter your organisation address'),
    industry: Yup.string().required('Please select your organisation industry'),
    type: Yup.string().required('Please select your organisation type'),
  })

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  const orgService: OrganizationService = useMemo(() => {
    return new OrganizationService()
  }, [])

  const loadOrganizationData = useCallback(async () => {
    setIsLoading(true)
    try {
      const res = await loadingService.await(orgService.getOrgData())
      if (res === null || res.status !== 'success' || res.result === null)
        return

      if (res?.result !== null) {
        const address = res?.result?.address?.split(',') ?? []
        const formattedAddress = address.map((component) => component.trim())

        let streetName = ''
        let city = ''
        let state = ''
        let postalCode = ''

        if (formattedAddress.length >= 3) {
          streetName = formattedAddress[0].replace(/,/g, '') // Remove commas from streetName
          city = formattedAddress[formattedAddress.length - 3]
          state = formattedAddress[formattedAddress.length - 2]
          postalCode = formattedAddress[formattedAddress.length - 1]
        }
        setInputLoc(streetName ?? res.result?.address)
        setIsLoading(false)
        setInitialVal({
          name: res?.result?.name ?? '',
          abn: res?.result?.ABN ?? '',
          address: `${streetName.trim()},${city.trim()},${state.trim()},${postalCode.trim()}`,
          industry: res?.result?.industry ?? '',
          type: res?.result?.type ?? '',
          streetName,
          city,
          postalCode,
          state,
        })
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }, [loadingService, orgService])

  useEffect(() => {
    if (masterData?.masterData) {
      setOrgTypes(
        masterData.masterData
          .filter((fd: any) => fd.category === 'org_type')
          .sort((a: any, b: any) => a.order - b.order)
      )
      setIndustryTypes(
        masterData.masterData
          .filter((fd: any) => fd.category === 'organization_industry')
          .sort((a: any, b: any) => a.order - b.order)
      )
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.')
      }
    }
  }, [masterData])

  useEffect(() => {
    loadOrganizationData()
  }, [loadOrganizationData])

  const [locations, setLocations] = useState([])
  const [addresses, setAddresses] = useState([])
  const handleLocationChange = async (
    event: string,
    values: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    event ? setInputLoc(event) : setInputLoc(values.suburb)
    try {
      if (event === '') {
        setFieldValue('clientAddress', '')
        setFieldValue('city', '')
        setFieldValue('state', '')
        setFieldValue('postalCode', '')
        setLocations([])
        setInputLoc('')
      } else {
        setIsLoading(true)
        if (inputLoc === '') {
          setIsLoading(false)
          setLocations([])
          return
        }
        const response = await axios.get(
          `${Urls.APP_BASE_URL}/location/autocompletePlace?input=${inputLoc}&specific=true`
        )
        const arr = response.data
        setAddresses(arr)
        setLocations(
          arr.map((value: any) => {
            return {
              value: value.address,
              text: value.address,
            }
          })
        )
        setIsLoading(false)

        // Find the selected address
        const selectedAddress = arr.find((item: any) => item?.address === event)

        // Set the address values in formik
        setFieldValue('streetName', selectedAddress?.streetAddress)
        setFieldValue('city', selectedAddress?.city)
        setFieldValue('state', selectedAddress?.state)
        setFieldValue('postalCode', selectedAddress?.postalCode)
      }
    } catch (error: any) {
      setIsLoading(false)
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  const handleAddressSelect = async (i: any, setFieldValue: any) => {
    if (addresses && addresses.length > 0) {
      const selectedAddress: any = addresses.find(
        (item: any) =>
          item.streetAddress === i.value || item.address === i.value
      )

      if (selectedAddress) {
        setFieldValue('streetName', selectedAddress.streetAddress)
        setFieldValue('city', selectedAddress.city)
        setFieldValue('state', selectedAddress.state)
        setFieldValue('postalCode', selectedAddress.postalCode)

        setInputLoc(selectedAddress.streetAddress)
      }
    }
  }

  const handleSubmit = async (values: FormikValues, { setSubmitting }: any) => {
    setSubmitting(true)
    setIsLoading(true)
    const data = {
      name: values.name,
      ABN: values.abn,
      address: `${values.streetName.trim()},${values.city.trim()},${values.state.trim()},${values.postalCode.trim()}`,
      industry: values.industry,
      type: values.type,
    }
    try {
      const res = await loadingService.await(orgService.updateOrgData(data))
      if (res !== null && res.status === 'success') {
        setIsLoading(false)
        window.location.reload()
      }
    } catch (error: any) {
      setIsLoading(false)
      setErrorModel(true)
      setErrorMessage(error.message)
    }
    setSubmitting(false)
  }

  // Handle cancel button click
  const handleCancel = () => {
    // Call onCancel callback function from parent component
    onCancel(true)
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}

      <Formik
        initialValues={initialVal}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form className={styles.modal}>
            <div>
              <CustomInputField
                name={'name'}
                placeholder={'Organisation Name *'}
                className={'mt-2'}
                onChange={(event: FormikValues) =>
                  setFieldValue('name', event.target.value)
                }
              />
              <CustomInputField
                name={'abn'}
                placeholder={'ABN'}
                className={'mt-2'}
                onChange={(event: FormikValues) =>
                  setFieldValue('abn', event.target.value)
                }
              />
              <div className="mt-2">
                <CustomDropdown
                  name={'streetName'}
                  placeHolder={'Street Name *'}
                  onChange={(e: string) => {
                    handleLocationChange(e, values, setFieldValue)
                  }}
                  dataList={locations}
                  selectedValue={{ value: inputLoc, text: inputLoc }}
                  getSelectedItem={(i) => {
                    setInputLoc(i.text)
                    handleAddressSelect(i, setFieldValue)
                  }}
                />
              </div>
              <div className="d-flex justify-content-between mt-2">
                <CustomInputField
                  name={'city'}
                  placeholder={'Suburb *'}
                  className={''}
                  onChange={(event: FormikValues) =>
                    setFieldValue('city', event.target.value)
                  }
                  value={values.city}
                />
                &nbsp;&nbsp;&nbsp;
                <CustomInputField
                  name={'postalCode'}
                  placeholder={'Post Code *'}
                  className={''}
                  onChange={(event: FormikValues) =>
                    setFieldValue('postalCode', event.target.value)
                  }
                  value={values.postalCode}
                />
                &nbsp;&nbsp;&nbsp;
                <CustomInputField
                  name={'state'}
                  placeholder={'State *'}
                  className={''}
                  onChange={(event: FormikValues) =>
                    setFieldValue('state', event.target.value)
                  }
                  value={values.state}
                />
              </div>
              <div className={'mt-2'}>
                <CustomDropdown
                  name={`industry`}
                  placeHolder={'Industry *'}
                  dataList={industryTypes.map((value: any) => ({
                    text: value.value,
                    value: value.id,
                  }))}
                  selectedValue={(() => {
                    const found = industryTypes.find(
                      (fd) => fd.id === values.industry
                    )
                    return found
                      ? { value: found.id, text: found.value }
                      : undefined
                  })()}
                  getSelectedItem={(item) =>
                    setFieldValue('industry', item.value)
                  }
                />
              </div>
              <div
                className={`container mt-3 justify-content-between ${styles.borderBottomLight}`}
              >
                <div className="row">
                  <div className="col-sm-4">
                    <h6 className={'text-bold gray-color-text mt-3'}>
                      Organisation type
                    </h6>
                  </div>
                  <div className="col mt-3">
                    <div className="container">
                      <div className="row">
                        {orgTypes.map((value: any, index) => (
                          <div className="form-check col-md-6" key={index}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="organization"
                              id="organization"
                              value={value.id}
                              onChange={(e) =>
                                setFieldValue('type', e.target.value)
                              }
                              checked={values.type === value.id}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="organization"
                            >
                              <h6 className={'text-normal gray-color-text'}>
                                {value.value}
                              </h6>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'d-flex justify-content-between mt-5'}>
                <div>
                  <CustomButton
                    type={'button'}
                    className={`p-2 ${styles.whiteBtn}`}
                    icon={<RecycleBinIcon />}
                    iconSide={'left'}
                    text={'Discard'} /* onClick={closeModal} */
                  />
                </div>

                <div className="d-flex justify-content-between mt-5">
                  <div className="mx-3">
                    <CustomButton
                      icon={<RecycleBinIcon />}
                      type={'button'}
                      className={`${styles.outlineBtn}`}
                      text={'Discard'}
                      variant={'special'}
                      onClick={() => handleCancel()}
                    />
                  </div>
                  <div>
                    <CustomButton
                      type={'submit'}
                      text={'Update Info'}
                      className={styles.modalBtn}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default UpdateCompanyDetails
