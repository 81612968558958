import { useContext, useEffect, useMemo, useState } from 'react'
import styles from './../dashboard/dashboard.module.scss'
import TopNavigation from '../topNavigation/topNavigation'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import color from '../../styles/variables.module.scss'
import BagIcon from '../../icons/bag.icon'
import WalkingManIcon from '../../icons/walkingMan.icon'
import DegreeCapIcon from '../../icons/degreeCap.icon'
import SendIcon from '../../icons/send.icon'
import DescriptionIcon from '../../icons/discription.icon'
import ResourceCard from './resourceCard'
import LeftArrow from '../../icons/leftArrow.icon'
import CustomButton from '../shared/CustomButton/customButton'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingService from '../../services/loading/loading.service'
import JobService from '../../services/job/job.service'
import {
  CandidateDetails,
  GetJobResponse,
  JobCreateResponse,
  JobDetails,
  Top3CandidatesResponse,
} from '../../services/job/job.res.model'
import CostIcon from '../../icons/cost.icon'
import { MasterDataContext } from '../../context/masterData'
import ClockIcon from '../../icons/clock.icon'
import { JobStatus, WorkCommitment } from '../../services/job/job.model'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

export enum HiringStage {
  Screening = 'Screening',
  CultureCatchUp = 'Culture_Catch_Up',
  Interview = 'Interview',
  ReferenceCheck = 'Reference_Check',
  Negotiation = 'Negotiation',
  PaperWork = 'Paper_Work',
  Completed = 'Completed',
}

const TopThreeResources = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const masterData = useContext(MasterDataContext)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<any>('')
  const [isLoading, setIsLoading] = useState(false)
  const [candidates, setCandidates] = useState<CandidateDetails[]>([])
  const [job, setJob] = useState<JobDetails>()
  const [educationLevel, setEducationLevel] = useState<any>()
  const [description, setDescription] = useState<string>(
    job?.job_description ?? ''
  )
  const [descriptionEditable, setDescriptionEditable] = useState<boolean>(false)
  const [jobStatus, setJobStatus] = useState<string>('')
  const loadingService = useMemo(() => new LoadingService(setIsLoading), [])
  const jobService = useMemo(() => new JobService(), [])

  const findStatus = (data: any) => {
    const Completed = data.filter(
      (a: any) => a.stage === HiringStage.Completed
    ).length
    const PaperWork = data.filter(
      (a: any) => a.stage === HiringStage.PaperWork
    ).length
    const Negotiation = data.filter(
      (a: any) => a.stage === HiringStage.Negotiation
    ).length
    const ReferenceCheck = data.filter(
      (a: any) => a.stage === HiringStage.ReferenceCheck
    ).length
    const Interview = data.filter(
      (a: any) => a.stage === HiringStage.Interview
    ).length
    const CultureCatchUp = data.filter(
      (a: any) => a.stage === HiringStage.CultureCatchUp
    ).length
    const Screening = data.filter(
      (a: any) => a.stage === HiringStage.Screening
    ).length
    if (Completed) {
      setJobStatus('Completed')
      return
    } else if (PaperWork) {
      setJobStatus('Paper Work')
      return
    } else if (Negotiation) {
      setJobStatus('Negotiation')
      return
    } else if (ReferenceCheck) {
      setJobStatus('Reference Check')
      return
    } else if (Interview) {
      setJobStatus('Interview')
      return
    } else if (CultureCatchUp) {
      setJobStatus('Culture Catch Up')
      return
    } else if (Screening) {
      setJobStatus('Screening')
      return
    } else {
      setJobStatus('Pending')
    }
  }
  useEffect(() => {
    if (!location.state || !location.state.jobID) {
      navigate('/jobs')
    }
    loadingService
      .await(jobService.getTop3Candidates({ id: location.state.jobID }))
      .then((res: Top3CandidatesResponse | null) => {
        if (
          res !== null &&
          (res.status === 'success' || res.status === 'limited_success')
        ) {
          setCandidates(res.result)
        }
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    loadingService
      .await(jobService.getJob({ id: location.state.jobID }))
      .then((res: GetJobResponse) => {
        if (res.status === 'success' && res.result) {
          setJob(res.result)
        }
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    loadingService
      .await(jobService.getHiringProcesses(location.state.jobID))
      .then((res: any) => {
        findStatus(res.result)
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [navigate, location.state, jobService, loadingService])

  const handleSaveDescription = () => {
    if (!job) {
      return
    }
    loadingService
      .await(
        jobService.editJob({ id: job.id }, { job_description: description })
      )
      .then((res: JobCreateResponse | null) => {
        if (res !== null && res.status === 'success') {
          setDescriptionEditable(false)
          setJob(res.result)
        }
      })
  }

  useEffect(() => {
    if (masterData?.masterData) {
      setIsLoading(true)
      if (job) {
        setEducationLevel(
          masterData.masterData.filter(
            (fd: any) => fd.id === job.minimum_qualification
          )[0]?.value
        )
        setIsLoading(false)
      } else {
        setIsLoading(false)
        setEducationLevel('')
      }
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.')
      }
    }
  }, [job, masterData])
  useEffect(() => {
    window.scrollTo(0, 0) // Scrolls to the top of the page
  }, [])
  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <div className={'w-100'}>
          <TopNavigation organization tabValue={'2'} organizationLogo />
          <div className={styles.content}>
            <div className={styles.leftSide}>
              <div className={styles.mobileView}>
                <CustomButton
                  type={'button'}
                  text={'Go Back'}
                  icon={<LeftArrow />}
                  iconSide={'left'}
                  className={styles.whiteBtn}
                  onClick={() => {
                    navigate('/jobs')
                  }}
                />
                <h6 className={'placeHolder-color mt-3'}>
                  Jobs /
                  <span className={'text-almostBlack ms-2'}>{job?.title}</span>
                </h6>
              </div>
              <div className={`mt-5 ${styles.statusCard}`}>
                <div
                  className={
                    'd-flex justify-content-between align-items-center'
                  }
                >
                  <h1 className={'text-size-jobTitle'}>{job?.title}</h1>
                  {job && job.jobs_status === JobStatus.Inactive && (
                    <h6 className={`${styles.inactiveTag}`}>
                      {job.jobs_status.charAt(0).toUpperCase() +
                        job.jobs_status.slice(1)}
                    </h6>
                  )}
                  <div className={styles.screening}>
                    <h6 className={'text-success'}>{jobStatus}</h6>
                  </div>
                </div>
                <div className={'d-flex align-items-center mt-3 flex-wrap'}>
                  {job?.skills
                    .filter((skill) => skill.type === 'task_expertise')
                    .map((item, index) => (
                      <div
                        key={index}
                        className={`mb-2 ${styles.payableTag}`}
                        id={`taskExpertise_${index}`}
                      >
                        <h6>{item.value}</h6>
                      </div>
                    ))}
                </div>
                <div className={`d-flex mt-5 pt-4 pb-4 ${styles.borderBottom}`}>
                  <div className={'d-flex me-2'}>
                    <BagIcon />
                    <h6 className={'ms-3 gray-color-text text-bold'}>
                      {job?.commitment === WorkCommitment.PermanentFullTime
                        ? 'Full Time'
                        : WorkCommitment.PermanentPartTime
                          ? 'Part Time'
                          : WorkCommitment.Casual
                            ? 'Casual'
                            : 'Unknown Commitment'}
                    </h6>
                  </div>
                  <div className={styles.borderClass}>
                    <WalkingManIcon />
                    <h6 className={'ms-3 gray-color-text text-bold'}>
                      {job?.work_type}
                    </h6>
                  </div>
                  <div className={styles.borderClass}>
                    <ClockIcon />
                    <h6 className={'ms-3 gray-color-text text-bold'}>
                      {job?.weekly_work_hours} hrs per week
                    </h6>
                  </div>
                </div>
                <div
                  className={`d-flex justify-content-between align-items-center pt-4 pb-4 ${styles.borderBottom}`}
                >
                  <div className={'d-flex align-items-center'}>
                    <CostIcon />
                    <h6 className={'ms-3 gray-color-text'}>Role Cost</h6>
                    <h6 className={'ms-3'}>
                      ${Math.floor(job?.approved_cost_per_month ?? NaN) || '?'}
                      /per month
                    </h6>
                  </div>
                  {/*<h6 className={'gray-color-text'}>{(((job?.approved_hours_per_month ?? NaN) / 4).toFixed(3) || '?')} hrs per week</h6>*/}
                </div>
                <div className={`mt-4 mb-4 pt-4 pb-4 ${styles.borderBottom}`}>
                  <div className={'d-flex align-items-center'}>
                    <DegreeCapIcon />
                    <h6 className={'ms-2 gray-color-text'}>Education</h6>
                  </div>
                  <h6 className={'mt-3'}>{educationLevel}</h6>
                </div>
                <div className={`mt-4 mb-4 pt-4 pb-4 ${styles.borderBottom}`}>
                  <div className={'d-flex align-items-center '}>
                    <div className={'d-flex align-items-center '}>
                      <div>
                        <div className={'d-flex align-items-center'}>
                          <BagIcon />
                          <h6 className={'ms-2 gray-color-text'}>Experience</h6>
                        </div>
                        <h6>{job?.minimum_years_of_experience} Years</h6>
                      </div>
                    </div>
                  </div>
                  <div className={'mt-3'}>
                    <div className={'d-flex align-items-center'}>
                      <div>
                        <div className={'d-flex align-items-center'}>
                          <SendIcon color={color.textLightColor} />
                          <h6 className={'ms-2 gray-color-text'}>Start Date</h6>
                        </div>
                        <h6>
                          {' '}
                          {job?.start_date &&
                            job?.start_date &&
                            (() => {
                              const dateObj = new Date(job.start_date)
                              const day = dateObj
                                .getDate()
                                .toString()
                                .padStart(2, '0')
                              const month = (dateObj.getMonth() + 1)
                                .toString()
                                .padStart(2, '0') // Month is zero-based
                              const year = dateObj.getFullYear()
                              return `${day}-${month}-${year}`
                            })()}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'mt-4'}>
                  <div className={'d-flex align-items-center'}>
                    <DescriptionIcon />
                    <h6 className={'ms-2 gray-color-text'}>Role Description</h6>
                  </div>
                  {descriptionEditable ? (
                    <textarea
                      className={styles.width100}
                      defaultValue={job?.job_description ?? ''}
                      rows={10}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  ) : (
                    <div>
                      <h6 className={'mt-3 text-normal'}>
                        {job?.job_description}
                      </h6>
                      <p>Powered by GPT-4.</p>
                    </div>
                  )}
                </div>
                <div className={styles.bottomContent}>
                  {descriptionEditable ? (
                    <div className={'d-flex align-items-center'}>
                      <CustomButton
                        text={'Discard Changes'}
                        disabled={isLoading}
                        className={styles.whiteBtn}
                        onClick={() => setDescriptionEditable(false)}
                      />
                      <CustomButton
                        text={'Save Changes'}
                        loading={isLoading}
                        disabled={isLoading}
                        className={styles.saveBtn}
                        onClick={handleSaveDescription}
                      />
                    </div>
                  ) : (
                    <CustomButton
                      text={'Edit Description'}
                      disabled={isLoading}
                      className={styles.saveBtn}
                      onClick={() => setDescriptionEditable(true)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.rightSide}>
              <div className={styles.webView}>
                <CustomButton
                  type={'button'}
                  text={'Go Back'}
                  icon={<LeftArrow />}
                  iconSide={'left'}
                  className={styles.whiteBtn}
                  onClick={() => {
                    navigate('/jobs')
                  }}
                />

                <h6 className={'placeHolder-color mt-3'}>
                  Roles /
                  <span className={'text-almostBlack ms-2'}>{job?.title}</span>
                </h6>
              </div>
              {job && job.jobs_status === JobStatus.Inactive ? (
                <>
                  {' '}
                  <h3
                    className={'text-almostBlack mb-5 text-bolder mt-4 mt-xl-0'}
                  >
                    Job Inactive
                  </h3>
                </>
              ) : (
                <>
                  {candidates.length > 0 ? (
                    <h3
                      className={
                        'text-almostBlack mb-5 text-bolder mt-4 mt-xl-0'
                      }
                    >
                      Top {candidates.length} matching resource
                      {candidates.length > 1 && 's'}
                    </h3>
                  ) : (
                    <h3
                      className={
                        'text-almostBlack mb-5 text-bolder mt-4 mt-xl-0'
                      }
                    >
                      No matching resources found
                    </h3>
                  )}
                </>
              )}

              {job &&
                job.jobs_status !== JobStatus.Inactive &&
                candidates.map((candidate: CandidateDetails, index: number) => (
                  <div className={'mt-3'} key={index}>
                    {candidate && (
                      <ResourceCard
                        id={`${index + 1}`}
                        description={
                          candidate.overview.split(' ').length > 50
                            ? candidate.overview
                                .split(' ')
                                .slice(0, 50)
                                .join(' ') + '...'
                            : candidate.overview
                        }
                        candidate={candidate}
                        pageIsLoading={isLoading}
                        job={job}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default TopThreeResources
