import { useEffect, useMemo, useState } from 'react'
import styles from './../assesmentCard/assesmentCard.module.scss'
import colors from '../../styles/variables.module.scss'
import BagIcon from '../../icons/bag.icon'
import CustomButton from '../shared/CustomButton/customButton'
import LocationIcon from '../../icons/location.icon'
import EmployeesIcon from '../../icons/employees.icon'
import CustomModal from '../shared/customModal/customModal'
import CompanySizingModal from './companySizingModal'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LoadingService from '../../services/loading/loading.service'
import JobService from '../../services/job/job.service'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import PhoneIcon from '../../icons/phone.icon'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

type Props = {
  id: string
  name: string
  financials_connected: boolean
  location: string
  accountHolder?: any
  pageIsLoading: boolean
  systemExpertise?: string[]
}

const CompanyCard: React.FC<Props> = ({
  id,
  name,
  financials_connected,
  location,
  pageIsLoading,
  systemExpertise,
  accountHolder,
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<any>('')
  const [companySizing, setCompanySizing] = useState(false)
  const [numberOfJobs, setNumberOfJobs] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const loadingService = useMemo(() => new LoadingService(setIsLoading), [])
  const jobService = useMemo(() => new JobService(), [])

  // Get the number of jobs
  useEffect(() => {
    loadingService
      .await(jobService.getOrgJobs({ id }))
      .then((res) => {
        if (res !== null && res.status === 'success' && res.result !== null) {
          setNumberOfJobs(res.result.length)
        }
      })
      .catch((error: any) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [id, loadingService, jobService])

  useEffect(() => {
    const state = searchParams.get('state')
    if (state !== null) {
      try {
        const stateObj = JSON.parse(state)
        if (stateObj !== null && stateObj.id === id) {
          setCompanySizing(true)
        }
      } catch (err: any) {
        setErrorModel(true)
        setErrorMessage(err.message)
      }
    }
  }, [id, searchParams])

  return (
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
      <div className={styles.jobsCard} style={{ borderColor: colors.gray200 }}>
        <div
          className={
            'd-flex justify-content-between align-items-center w-100 h-auto'
          }
        >
          <div
            className={` justify-content-between w-100 ${styles.displayFlex}`}
          >
            <h4 className={'text-bolder text-almostBlack'}>{name}</h4>
            <div className={`align-items-center ${styles.displayFlex}`}>
              {financials_connected ? (
                <CustomButton
                  className={'mt-2 mt-md-0'}
                  text={'Update Accounting System'}
                  disabled={pageIsLoading}
                  onClick={() => setCompanySizing(true)}
                />
              ) : systemExpertise &&
                systemExpertise?.every((v: any) =>
                  ['XERO', 'MYOB']?.includes(v)
                ) ? (
                <CustomButton
                  className={'mt-2 mt-md-0'}
                  text={'Connect Accounting Data File'}
                  disabled={pageIsLoading}
                  onClick={() => setCompanySizing(true)}
                />
              ) : (
                ''
              )}

              <CustomButton
                variant={'transparent'}
                text={'Needs Analysis'}
                className={`ms-0 ms-md-3 mt-2 mt-md-0 ${styles.mobilewhiteBtn}`}
                disabled={pageIsLoading}
                onClick={() => navigate('/analysis', { state: { orgID: id } })}
              />
            </div>
          </div>
        </div>
        <div className={'d-md-flex mt-5'}>
          <div className={'d-flex me-md-4 mb-2 mb-md-0'}>
            <BagIcon />
            <h6 className={'ms-3 gray-color-text'}>
              {numberOfJobs} Role Postings
            </h6>
          </div>
          <div className={styles.borderClassOrg}>
            <EmployeesIcon />
            <h6 className={'ms-3 gray-color-text'}>{accountHolder.name}</h6>
          </div>
          {location && (
            <div className={`mt-2 mt-md-0 ${styles.borderClassOrg}`}>
              <LocationIcon />
              <h6 className={'ms-3 gray-color-text'}>{location}</h6>
            </div>
          )}
          <div className={'d-flex ms-md-4 mt-2 mt-md-0'}>
            <PhoneIcon />
            <h6 className={'ms-3 gray-color-text'}>
              {accountHolder.phone_number}
            </h6>
          </div>
        </div>
        <CustomModal
          open={companySizing}
          onCloseModal={(val) => setCompanySizing(val)}
          hideCloseButton={true}
        >
          <CompanySizingModal
            closeModal={() => setCompanySizing(false)}
            orgID={id}
            page={'companies'}
            availableOptions={systemExpertise ?? []}
          />
        </CustomModal>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default CompanyCard
