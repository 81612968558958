/**
 * Added localhost links and process env when under production
 */

export const Urls = {
  APP_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  APP_COGNITO: process.env.REACT_APP_COGNITO_URL,
  APP_REDIRECT: process.env.REACT_APP_REDIRECT_URL,
  APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
}
