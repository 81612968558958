import { ChangeEvent, useContext } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styles from '../gettingStart/GettingStart.module.scss'
import CustomButton from '../shared/CustomButton/customButton'
import CustomInputField from '../shared/customInputField/CustomInputField'
import { Formik, Form, FormikValues } from 'formik'
import * as Yup from 'yup'
import 'yup-phone'
import LoadingService from '../../services/loading/loading.service'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import UserService from '../../services/user/user.service'
import OrganizationService from '../../services/organization/organization.service'
import { Auth } from 'aws-amplify'
import { MasterDataContext } from '../../context/masterData'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

type Props = {
  onCancel: (isCancelled: boolean) => void // Callback function to handle cancel event
}

export type AccountDetailProps = {
  fullName: string
  email: string
  contactNo: string
  how_did_you_hear_about_us: string
  accountReferralName: string
  referral_text: string
}

const UpdateAccountProfile: React.FC<Props> = ({ onCancel }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [errorModel, setErrorModel] = useState(false)
  const masterData = useContext(MasterDataContext)
  const [howDidYouHearAboutUsOptions, setHowDidYouHearAboutUsOptions] =
    useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [initialVal, setInitialVal] = useState<AccountDetailProps>(() => {
    return {
      fullName: '',
      email: '',
      contactNo: '',
      how_did_you_hear_about_us: '',
      accountReferralName: '',
      referral_text: '',
    }
  })

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Please enter your full name'),
    contactNo: Yup.string()
      .matches(
        /^(?:\+61\d{9}|04\d{8})$/,
        'Please use the format +61XXXXXXXXX or 04XXXXXXXX'
      )
      .required('Please enter your contact number.')
      .phone('AU', false, 'Please use the format +61XXXXXXXXX or 04XXXXXXXX.'),
    how_did_you_hear_about_us: Yup.string(),
    referral_text: Yup.string().when('how_did_you_hear_about_us', {
      is: 'Accountant Referral',
      then: Yup.string().required('Please enter the account referral name'),
      otherwise: Yup.string(),
    }),
  })

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  const userService: UserService = useMemo(() => {
    return new UserService()
  }, [])

  const orgService: OrganizationService = useMemo(() => {
    return new OrganizationService()
  }, [])

  useEffect(() => {
    if (masterData?.masterData) {
      setHowDidYouHearAboutUsOptions(
        masterData.masterData
          .filter((fd: any) => fd.category === 'how_did_you_hear_about_us')
          .sort((a: any, b: any) => a.order - b.order)
      )
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData])

  function addCountryCode(phoneNumber: any) {
    // Remove any non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, '')

    // Check if the number starts with '0'
    if (digitsOnly.startsWith('0')) {
      // Replace the '0' with '61' (Australian country code)
      return `+61${digitsOnly.substring(1)}`
    } else {
      // If it doesn't start with '0', assume it already has the country code
      return phoneNumber
    }
  }

  const loadUserData = useCallback(async () => {
    try {
      const res = await loadingService.await(orgService.getOrgData())

      if (res && res?.result !== null) {
        setInitialVal({
          fullName: res?.result.name ?? '',
          email: res?.result.email ?? '',
          contactNo: res?.result?.phone ?? '',
          how_did_you_hear_about_us:
            res?.result.how_did_you_hear_about_us ?? '',
          accountReferralName:
            (howDidYouHearAboutUsOptions.find(
              (item: any) => item.id === res?.result?.how_did_you_hear_about_us
            ) === undefined && res?.result.how_did_you_hear_about_us !== ''
              ? res?.result.accountReferralName
              : '') ?? '',
          referral_text: res?.result?.referral_text ?? '',
        })
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingService, userService])

  useEffect(() => {
    loadUserData()
  }, [loadUserData, howDidYouHearAboutUsOptions])

  // Handle cancel button click
  const handleCancel = () => {
    // Call onCancel callback function from parent component
    onCancel(true)
  }

  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting }: { setSubmitting: (val: boolean) => void }
  ) => {
    setSubmitting(true)
    try {
      await loadingService.await(
        userService.updateUserData({
          mobile_number: `${values.contactNo}`,
        })
      )
      const res2 = await loadingService.await(
        orgService.updateOrgData({
          name: values.fullName,
          phone: `${values.contactNo}`,
          how_did_you_hear_about_us: values.how_did_you_hear_about_us,
          referral_text: values?.referral_text ?? '',
        })
      )
      const currentUser = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(currentUser, {
        phone_number: addCountryCode(values.contactNo),
      })
      if (res2 !== null) {
        window.location.reload()
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <Formik
          initialValues={initialVal}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form className={styles.modal}>
              <CustomInputField
                name={'fullName'}
                placeholder={'Organisation Name *'}
                className={'mt-2'}
                onChange={(event: any) =>
                  setFieldValue('fullName', event.target.value)
                }
              />
              <CustomInputField
                disabled={true}
                name={'email'}
                placeholder={'Email *'}
                className={'mt-2'}
              />
              <CustomInputField
                name={'contactNo'}
                placeholder={'Mobile Phone *'}
                className={'mt-2'}
                onChange={(event: any) =>
                  setFieldValue('contactNo', event.target.value)
                }
              />
              <div
                className={`container mt-3 justify-content-between ${styles.borderBottomLight}`}
              >
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className={'text-bold gray-color-text mt-3'}>
                      How did you hear about us?
                    </h6>
                  </div>
                  <div className="col-sm-1">&nbsp;</div>
                  <div className="col-sm-8 mt-3">
                    <div className="row">
                      {[0, 1].reduce(
                        (columns: JSX.Element[], column: number) => {
                          const startIndex =
                            column *
                            Math.ceil(howDidYouHearAboutUsOptions.length / 2)
                          const endIndex =
                            startIndex +
                            Math.ceil(howDidYouHearAboutUsOptions.length / 2)
                          const options = howDidYouHearAboutUsOptions.slice(
                            startIndex,
                            endIndex
                          )

                          columns.push(
                            <div className="col-sm-6" key={column}>
                              {options.map((option: any, index: number) => (
                                <div className="form-check" key={index}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value={option.id}
                                    name="how_did_you_hear_about_us"
                                    id={`how_did_you_hear_about_us-${index}`}
                                    onChange={(e) => {
                                      setFieldValue(
                                        'how_did_you_hear_about_us',
                                        e.target.value
                                      )
                                    }}
                                    checked={
                                      values.how_did_you_hear_about_us ===
                                      option.id
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`how_did_you_hear_about_us-${index}`}
                                  >
                                    <h6
                                      className={'text-normal gray-color-text'}
                                    >
                                      {option.value}
                                    </h6>
                                  </label>
                                </div>
                              ))}
                            </div>
                          )

                          return columns
                        },
                        []
                      )}
                    </div>

                    {errors.how_did_you_hear_about_us &&
                      touched.how_did_you_hear_about_us && (
                        <div className={styles.error}>
                          {errors.how_did_you_hear_about_us}
                        </div>
                      )}

                    {['Accountant Referral', 'Other'].some((ele) =>
                      masterData.masterData
                        .filter(
                          (fd: any) =>
                            fd.category === 'how_did_you_hear_about_us'
                        )
                        ?.filter(
                          (fd: any) =>
                            fd.id === values.how_did_you_hear_about_us
                        )
                        ?.map((md: any) => md.value)
                        .includes(ele)
                    ) && (
                      <div className="mt-3">
                        <div className={styles.answerLength}>
                          <CustomInputField
                            name="referral_text"
                            placeholder=""
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setFieldValue('referral_text', e.target.value)
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={'d-flex justify-content-between mt-5'}>
                <div>
                  <div className="mx-3">
                    <CustomButton
                      disabled={isLoading}
                      type={'button'}
                      className={`${styles.outlineBtn}`}
                      text={'Discard'}
                      variant={'special'}
                      icon={<RecycleBinIcon />}
                      onClick={() => handleCancel()}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <CustomButton
                      disabled={isLoading}
                      type={'submit'}
                      text={'Update Info'}
                      className={styles.modalBtn}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default UpdateAccountProfile
