import axios from 'axios'
import { Urls as URLS } from '../../context/Urls'

export default class HttpService {
  public async sendGetRequest<ResponseType>(
    endPoint: string,
    userToken?: string
  ): Promise<ResponseType | any> {
    try {
      const res = await axios.get(URLS.APP_BASE_URL + '/' + endPoint, {
        headers: userToken
          ? {
              Authorization: `Bearer ${userToken}`,
            }
          : {},
      })
      return res.data
    } catch (err: any) {
      throw new Error(err.response.data.message)
    }
  }

  public async sendPostRequest<ResponseType>(
    endPoint: string,
    userToken?: string,
    body?: object | null
  ): Promise<ResponseType | null> {
    try {
      const res = await axios.post(
        URLS.APP_BASE_URL + '/' + endPoint,
        body ?? null,
        {
          headers: userToken
            ? {
                Authorization: `Bearer ${userToken}`,
              }
            : {},
        }
      )
      return res.data
    } catch (err: any) {
      return err
    }
  }
}
