import { ReactElement, useState } from 'react'
import styles from './CustomImageUpload.module.scss'
import Avatar from '../../../images/avatar.png'
import EditIcon from '../../../icons/edit.icon'

type Props = {
  id: string
  filedText?: string
  fileSelect?: (e: File) => void
  loading?: boolean
  // buttonStatus?: string;
  disabled?: boolean
  icon?: ReactElement
  defaultFile?: string
  maxSize?: number
  onError?: (e: string) => void
  oversizeError?: string
}

const CustomImageUpload: React.FC<Props> = ({
  id,
  filedText,
  fileSelect,
  defaultFile,
  maxSize,
  onError,
  oversizeError,
}) => {
  const [file, setFile] = useState<Blob | MediaSource>()

  const handleImageSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      maxSize &&
      e.target.files?.[0].size &&
      e.target.files?.[0].size > maxSize
    ) {
      onError?.(oversizeError || 'File is too big')
      return
    }
    setFile(e.target.files?.[0])
    if (fileSelect && e.target.files?.[0]) {
      fileSelect(e.target.files?.[0])
    }
  }

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    if (e.dataTransfer.items && e.dataTransfer.items[0]) {
      const item = e.dataTransfer.items[0]
      if (item.kind === 'file') {
        const file = item.getAsFile()
        if (file) {
          if (maxSize && file.size && file.size > maxSize) {
            onError?.(oversizeError || 'File is too big')
            return
          }
          if (!file.type.includes('image')) {
            onError?.('File is not an image')
            return
          }
          setFile(file)
          if (fileSelect) {
            fileSelect(file)
          }
        }
      }
    }
  }

  return (
    <div
      className={styles.imageUpload}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {!file ? (
        <img
          src={defaultFile || Avatar}
          className={styles.imageSize}
          alt={'avatar'}
        />
      ) : (
        <img
          src={URL.createObjectURL(file)}
          className={styles.imageSize}
          alt={'avatarSelected'}
        />
      )}
      <label htmlFor={id} className={styles.textFiled}>
        <input
          type="file"
          id={id}
          style={{ display: 'none' }}
          accept="image/jpeg, image/jpg, image/png"
          onChange={handleImageSelect}
        />
        <p className={'light-text'}>{filedText}</p>
        <div className={styles.uploadIcon}>
          <EditIcon />
        </div>
      </label>
    </div>
  )
}

export default CustomImageUpload
