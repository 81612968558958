import { useContext } from 'react'
import styles from '../profile/Profile.module.scss'
import TopNavigation from '../topNavigation/topNavigation'
import CustomButton from '../shared/CustomButton/customButton'
import { useLocation, useNavigate } from 'react-router-dom'
import LeftArrowIcon from '../../icons/leftArrow.icon'
import BagIcon from '../../icons/bag.icon'
import StarIcon from '../../icons/star.icon'
import CertificateProfileIcon from '../../icons/certificateProfile'
import DegreeCapIcon from '../../icons/degreeCap.icon'
import VisaIcon from '../../icons/visa.icon'
import InfoIcon from '../../icons/info.icon'
import LocationIcon from '../../icons/location.icon'
import GreenDotIcon from '../../icons/greenDot.icon'
import { CandidateDetails, JobDetails } from '../../services/job/job.res.model'
import { useEffect, useMemo, useState } from 'react'
import JobService from '../../services/job/job.service'
import { UploadedFile } from '../../services/file/file.model'
import profilePic from '../../images/avatarSqure.png'
import DegreeIcon from '../../icons/degree.icon'
import CalenderIcon from '../../icons/calender.icon'
import CertificateIcon from '../../icons/certificate.icon'
import QuoteIcon from '../../icons/quote.icon'
import LoadingService from '../../services/loading/loading.service'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import ReviewModal, { Responses } from '../profile/reviewModal'
import CustomModal from '../shared/customModal/customModal'
import { MasterDataContext } from '../../context/masterData'
import { Qualification } from '../profile/educationProfile'
import { Certification } from '../profile/certificateProfile'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

const ProfileOverview = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const masterData = useContext(MasterDataContext)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<any>('')
  const [job, setJob] = useState<JobDetails>()
  const [candidate, setCandidate] = useState<CandidateDetails>()
  const [profileImage, setProfileImage] = useState<UploadedFile | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [qualificationLevels, setQualificationLevels] = useState<any[]>([])
  const [certificateLevels, setCertificationLevels] = useState<any[]>([])
  const [qualifications, setQualifications] = useState<Qualification[]>([])
  const [certifications, setCertifications] = useState<Certification[]>([])
  const [reviewModal, setReviewModal] = useState(false)
  const [responses, setResponses] = useState<Responses>({
    response1: '',
    response2: '',
    response3: '',
    response4: '',
    additionalComments: '',
  })
  const [referees, setReferees] = useState<
    {
      id: string
      name: string
      email: string
      phone: string
      relationship: string
      organization: string
      status: string
      response1: string
      response2: string
      response3: string
      response4: string
      additionalComments: string
    }[]
  >([])

  const jobService = useMemo(() => new JobService(), [])

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  useMemo(() => {
    if (candidate) {
      loadingService
        .await(jobService.getRefereeComments(candidate.id))
        .then((res) => {
          if (res !== null && res.status === 'success') {
            setReferees(res.result)
          } else if (res !== null && res.status === 'no_data') {
            setReferees([])
          }
        })
        .catch((error: any) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
    } else {
      setIsLoading(false)
      setReferees([])
    }
  }, [loadingService, jobService, candidate])

  useEffect(() => {
    if (!location.state || !location.state.candidate || !location.state.job) {
      navigate('/jobs')
    }
    setCandidate(location.state.candidate)
    setJob(location.state.job)
    if (location.state.profileImage) {
      setProfileImage(location.state.profileImage)
    } else {
      jobService
        .getCandidateProfileImage({ id: location.state.candidate.id })
        .then(setProfileImage)
    }
  }, [jobService, location, navigate])

  useEffect(() => {
    if (candidate) {
      setQualifications(
        candidate.qualifications.map((q: any) => ({
          ...q,
          start_date: new Date(
            q.start_date === '1970-01-01' ? '2000-01-01' : q.start_date
          ),
          end_date: new Date(q.end_date),
        }))
      )
      setCertifications(
        candidate.certifications.map((q: any) => ({
          ...q,
          issue_date: new Date(
            q.issue_date === '1970-01-01' ? '2000-01-01' : q.issue_date
          ),
        }))
      )
    } else {
      setQualifications([])
    }
  }, [candidate, location.state.candidate])

  useEffect(() => {
    if (masterData?.masterData) {
      setQualificationLevels(
        masterData.masterData
          .filter((fd: any) => fd.category === 'qualification')
          .sort((a: any, b: any) => a.order - b.order)
      )
      setCertificationLevels(
        masterData.masterData
          .filter((fd: any) => fd.category === 'licences_and_certifications')
          .sort((a: any, b: any) => a.order - b.order)
      )
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.')
      }
    }
  }, [masterData])

  const groupSkillsByExpertise = () => {
    if (!candidate) return {}

    const groupedSkills: { [key: string]: string[] } = {} // Specify type annotations here
    candidate.skills.forEach((skill) => {
      if (!groupedSkills[skill.type]) {
        groupedSkills[skill.type] = []
      }
      groupedSkills[skill.type].push(skill.value)
    })
    return groupedSkills
  }

  const expertiseSkills = groupSkillsByExpertise()
  useEffect(() => {
    window.scrollTo(0, 0) // Scrolls to the top of the page
  }, [])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <TopNavigation organization tabValue={'2'} organizationLogo />
        <div className="d-lg-flex justify-content-evenly mx-5 my-5">
          <div
            className={`mx-lg-5 ${styles.profileOverview}  col-sm-12 col-lg-4`}
          >
            <div
              className={`d-flex align-items-center justify-content-between ${styles.borderBottom}`}
            >
              <div
                className={
                  'd-md-flex align-items-center justify-content-center'
                }
              >
                <img
                  src={profileImage ? profileImage.contents : profilePic}
                  alt={'avatar'}
                  className={styles.profilePic}
                />
                <div className={`my-2 ms-3`}>
                  {candidate?.location && (
                    <div className="d-flex my-3">
                      <LocationIcon color="gray" />
                      <h6 className={'text-normal mx-3'}>
                        {candidate?.location}
                      </h6>
                    </div>
                  )}
                  {candidate?.location && (
                    <div className="d-flex my-3">
                      <BagIcon color="gray" />
                      <span className={'d-flex mx-2'}>
                        <h6 className="text-normal">Availability-</h6>
                        <h6 className="gray-color-text px-2">
                          {candidate.notice_period_days === 0
                            ? 'Immediately'
                            : `${Math.ceil(candidate.notice_period_days / 7)} week${Math.ceil(candidate.notice_period_days / 7) > 1 ? 's' : ''}`}
                        </h6>
                      </span>
                    </div>
                  )}
                  {candidate?.citizenship_status && (
                    <div className="d-flex my-3">
                      <VisaIcon color="gray" />
                      <span className={'d-flex mx-3'}>
                        <h6 className="text-normal">Visa-</h6>
                        <h6 className="gray-color-text px-2">
                          {candidate?.citizenship_status}
                        </h6>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`mt-5`}>
              <div className={`d-flex align-items-center`}>
                <InfoIcon />
                <h6 className={'gray-color-text mx-3'}>Overview</h6>
              </div>
            </div>
            <div className={`text-normal gray-color-text mt-3`}>
              <address>{candidate?.overview}</address>
            </div>
          </div>
          <div className={` col-sm-12 col-lg-7`}>
            <div className={`col-2 mt-5`}>
              <CustomButton
                text={'Go back'}
                icon={<LeftArrowIcon />}
                iconSide={'left'}
                className={styles.whiteBtn}
                onClick={() =>
                  navigate('/topThreeResources', { state: { jobID: job?.id } })
                }
              />
            </div>
            <div className={`mt-3 ms-3`}>
              <span className="d-flex placeHolder-color">
                <h6>Roles /</h6>
                <h6 className="ms-2">{job?.title} /</h6>
                <h6 className="text-almostBlack ms-2">Candidate Profile</h6>
              </span>
            </div>
            <div className={`mt-5 ${styles.contentBox}`}>
              <div
                className={`d-flex align-items-center ${styles.borderBottom}`}
              >
                <div className={'d-flex align-items-center'}>
                  <StarIcon color="black" size={25} />
                  <h4 className={'text-almostBlack ms-3'}>
                    Key Skills Summary
                  </h4>
                </div>
              </div>
              <div>
                {candidate?.key_skills_summary
                  .split('.')
                  .filter((s) => s !== '')
                  .map((skill: string, index: number) => (
                    <div
                      className="d-flex mt-3"
                      key={`candidate-key_skill-item-${index}`}
                    >
                      <div>
                        <GreenDotIcon />
                      </div>
                      <h6 className="text-normal gray-color-text ms-3">
                        {skill}
                      </h6>
                    </div>
                  ))}
              </div>
            </div>
            <div className={`mt-5 ${styles.contentBox}`}>
              <div
                className={`d-flex align-items-center justify-content-between`}
              >
                <div className={'d-flex align-items-center'}>
                  <BagIcon color="black" />
                  <h4 className={'text-almostBlack ms-3'}>Expertise</h4>
                </div>
              </div>
              <div className={'d-flex flex-column mt-4'}>
                {Object.keys(expertiseSkills).map((expertise, index) => (
                  <div
                    className={styles.tagBox}
                    key={`expertise-item-main-${index}`}
                  >
                    <h5>
                      {expertise
                        .replace(/_/g, ' ')
                        .replace(/^(.)|\s(.)/g, (c) => c.toUpperCase())}
                    </h5>
                    <div className="d-flex flex-wrap">
                      {expertiseSkills[expertise].map((skill, subIndex) => (
                        <div
                          className={`mt-3 me-3 ${styles.payableTag}`}
                          key={`expertise-item-${subIndex}`}
                        >
                          <h6>{skill}</h6>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={`${styles.contentBox}`}>
              <div>
                <div
                  className={`d-flex align-items-center justify-content-between `}
                >
                  <div className={'d-flex align-items-center'}>
                    <DegreeCapIcon color="black" />
                    <h4 className={'text-almostBlack ms-3'}>Education</h4>
                  </div>
                </div>

                {/* Education cards */}
                <div>
                  {qualifications.map((qualification, index) => (
                    <div
                      className={styles.educationCard}
                      key={`education-certificates-card-${index}`}
                    >
                      <div className={'d-flex'}>
                        <div className={'d-flex align-items-center'}>
                          <DegreeIcon />
                        </div>
                        <div className={'ms-4'}>
                          <h6 className={'text-bold gray-color-text'}>
                            {qualificationLevels?.filter(
                              (fd: any) =>
                                fd.id ===
                                qualification.qualification.toLowerCase()
                            )[0]?.value ?? qualification.qualification}
                          </h6>
                          <h6
                            className={
                              'text-normal text font-size-semi gray-color-text mt-2'
                            }
                          >
                            {qualification.field_of_study}
                          </h6>
                          <p
                            className={
                              'text-normal text font-size-semi gray-color-text mt-2'
                            }
                          >
                            {qualification.institution}
                          </p>
                          <div className={'d-flex mt-2'}>
                            <CalenderIcon />
                            <p className={'ms-2 gray-color-text'}>
                              {qualification.start_date
                                ? qualification.start_date.toLocaleString(
                                    'en-AU',
                                    { month: 'long', year: 'numeric' }
                                  )
                                : '?'}{' '}
                              -{' '}
                              {qualification.end_date
                                ? qualification.end_date.toLocaleString(
                                    'en-AU',
                                    { month: 'long', year: 'numeric' }
                                  )
                                : '?'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={`${styles.borderBottom}`}></div>
              </div>
              <div className={`mt-5`}>
                <div
                  className={`d-flex align-items-center justify-content-between `}
                >
                  <div className={'d-flex align-items-center'}>
                    <CertificateProfileIcon color="black" />
                    <h4 className={'text-almostBlack ms-4'}>Certificates</h4>
                  </div>
                </div>

                {/* Certificate cards */}
                <div>
                  {certifications.map((cert: Certification, index: number) => (
                    <div
                      className={styles.educationCard}
                      key={`certificate-main-${index}`}
                    >
                      <div className={'d-flex align-items-center'}>
                        <CertificateIcon />
                        <div className={'ms-4'}>
                          <h6 className={'text-bold gray-color-text'}>
                            {certificateLevels?.filter(
                              (fd: any) => fd.id === cert.certification
                            )[0]?.value ?? cert.certification}
                          </h6>
                          <p
                            className={
                              'text-normal text font-size-semi gray-color-text'
                            }
                          >
                            {cert.organization}
                          </p>
                          <div className={'d-flex mt-1'}>
                            <CalenderIcon />
                            <p
                              className={`ms-2 gray-color-text text-normal ${styles.borderRight}`}
                            >
                              {cert.issue_date
                                ? cert.issue_date.toLocaleString() ===
                                  '1970-01-01'
                                  ? '2000-01-01'
                                  : cert.issue_date.toLocaleString('en-AU', {
                                      month: 'long',
                                      year: 'numeric',
                                    })
                                : ''}
                            </p>
                            <p className={'gray-color-text ms-0 ms-lg-4 '}>
                              Ref/Member No -
                              <span className={'ms-2 text-normal'}>
                                {cert.reference_id}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Reviews */}
            <div className={`${styles.contentBox}`}>
              <div>
                <div
                  className={`d-flex align-items-center justify-content-between `}
                >
                  <div className={'d-flex align-items-center'}>
                    <QuoteIcon color="black" size={25} />
                    <h4 className={'text-almostBlack ms-3'}>Reviews</h4>
                  </div>
                </div>
                {referees.length > 0 &&
                  referees.map((referee, index) => (
                    <>
                      {referee.status === 'posted_comment' ? (
                        <div
                          className={styles.educationCard}
                          key={`referee-comment-item-${index}`}
                        >
                          <div className={'d-flex align-items-center'}>
                            <div className={'ms-4'}>
                              <h6 className={'text-bold gray-color-text'}>
                                {referee.name}
                              </h6>
                              <div className={'d-flex mt-2'}>
                                <h6
                                  className={
                                    'mt-3 text-normal gray-color-text d-flex'
                                  }
                                >
                                  <span
                                    className={styles.elipse}
                                    style={{ width: '10%' }}
                                  >
                                    {referee.response1}
                                  </span>
                                  <span
                                    className={
                                      'pointer text-bold gray-color-text ms-2'
                                    }
                                    onClick={() => {
                                      setResponses({
                                        response1: referee.response1,
                                        response2: referee.response2,
                                        response3: referee.response3,
                                        response4: referee.response4,
                                        additionalComments:
                                          referee.additionalComments,
                                      })
                                      setReviewModal(true)
                                    }}
                                  >
                                    Read More
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={styles.educationCard}
                          key={`referee-comment-item-${index}`}
                        >
                          <div className={'d-flex align-items-center'}>
                            <div className={'ms-4'}>
                              <h6 className={'text-bold gray-color-text'}>
                                {referee.name}
                              </h6>
                              <div className={'d-flex mt-2'}>
                                <h6
                                  className={'mt-3 text-normal gray-color-text'}
                                >
                                  Review Pending
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {reviewModal && (
          <CustomModal
            open={reviewModal}
            onCloseModal={(val) => setReviewModal(val)}
          >
            <ReviewModal
              response1={responses.response1}
              response2={responses.response2}
              response3={responses.response3}
              response4={responses.response4}
              additionalComments={responses.additionalComments}
            />
          </CustomModal>
        )}
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default ProfileOverview
