import { useMemo, useState } from 'react'
import styles from '../gettingStart/GettingStart.module.scss'
import CustomButton from '../shared/CustomButton/customButton'
import UserService from '../../services/user/user.service'
import LoadingService from '../../services/loading/loading.service'
import { useNavigate } from 'react-router-dom'

type Props = {
  closeModal: () => void
}

const ConfirmDelete: React.FC<Props> = ({ closeModal }) => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    []
  )
  const userService: UserService = useMemo(() => new UserService(), [])

  async function deleteAccount() {
    setErrorMessage('')
    if (await loadingService.await(userService.deleteUser())) {
      navigate('/')
    } else {
      setErrorMessage('Something went wrong, please try again later')
    }
  }

  return (
    <div>
      <div
        className={`mt-3 justify-content-between ${styles.borderBottomLight}`}
      >
        {errorMessage !== '' && (
          <div className={'alert alert-danger mt-3'} role="alert">
            {errorMessage}
          </div>
        )}
        <h6 className={'text-normal'}>
          Lorem ipsum dolor sit amet consectetur. Cras ut neque rhoncus mattis
          ultrices nam viverra aliquet. Odio at ut elementum morbi tempor massa.{' '}
        </h6>
      </div>
      <div className={'d-flex justify-content-between mt-5'}>
        <div>&nbsp;</div>
        <div className="d-flex justify-content-between">
          <div className="mx-3">
            <CustomButton
              type={'button'}
              className={`${styles.outlineBtn}`}
              text={'No, Cancel'}
              variant={'special'}
              onClick={closeModal}
              disabled={isLoading}
            />
          </div>
          <div>
            <CustomButton
              type={'submit'}
              text={'Yes, Delete'}
              variant={'danger'}
              className={styles.modalBtn}
              onClick={deleteAccount}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmDelete
