import { useState } from 'react'
import CustomImageUpload from '../shared/CustomImageUpload/customImageUpload'
import styles from './../gettingStart/GettingStart.module.scss'
import FileService from '../../services/file/file.service'
import { UploadedFile } from '../../services/file/file.model'

interface Props {
  defaultImage?: string
  onImageUpdate: (image: UploadedFile) => void
  disabled?: boolean
  error?: string
}

export const ProfileImageUpload: React.FC<Props> = ({
  defaultImage,
  onImageUpdate,
  disabled = false,
  error: externalError,
}) => {
  const [internalError, setInternalError] = useState('')
  const fileService = new FileService()

  const handleImageUpload = async (file: File) => {
    if (file.size > 1024 * 1024) {
      setInternalError('Image size must be less than 1MB')
      return
    }

    try {
      const uploadedFile = await fileService.readFile(file)
      setInternalError('')
      onImageUpdate(uploadedFile)
    } catch (error) {
      setInternalError('Failed to process image')
    }
  }

  const error = externalError || internalError

  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        <CustomImageUpload
          maxSize={1024 * 1024}
          oversizeError="Image size must be less than 1MB"
          onError={setInternalError}
          disabled={disabled}
          defaultFile={defaultImage}
          id="profile"
          fileSelect={handleImageUpload}
        />
        {error && <div className={styles.error}>{error}</div>}
        <div>
          <h6 className="text-gray-600 text-sm">Max file size 1MB</h6>
          <h6 className="text-gray-600 text-sm">
            Supported files - .png, .jpeg, .jpg
          </h6>
        </div>
      </div>
    </div>
  )
}
